

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'

import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Reservation from './reservation/reducers'
import Vouchers from './vouchers/reducers'
import Adventures from './adventures/reducers'
import entities from './entities/reducers'
import Balances from './finances/reducers';

const authConfig = {
    key: 'auth',
    storage,
}

const reservationConfig = {
    key: 'reservation',
    storage: storageSession
}

const adventuresConfig = {
    key: 'adventures',
    storage: storageSession
}

const vouchersConfig = {
    key: 'vouchers',
    storage: storageSession
}

const entitiesConfig = {
    key: 'entities',
    storage: storageSession
}

const balancesConfig = {
    key: 'balances',
    storage: storageSession
}

export default combineReducers({
    Auth: persistReducer(authConfig, Auth),
    AppMenu,
    Layout,
    entities: persistReducer(entitiesConfig,entities),
    Reservation: persistReducer(reservationConfig, Reservation),
    Adventures: persistReducer(adventuresConfig, Adventures),
    Balances: persistReducer(balancesConfig, Balances),
    Vouchers: persistReducer(vouchersConfig, Vouchers)
});

export const testReducers = combineReducers({
    Auth,
    AppMenu,
    Layout,
    entities,
    Reservation,
    Adventures
})