
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LOGIN_USER, LOGOUT_USER, REGISTER_USER, FORGET_PASSWORD, SAVE_BANK_ATTRIBUTES, SAVE_BUSINESS_ATTRIBUTES, SEND_DOCUMENTS, UPDATE_USER } from './constants';
import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    updateUserSuccess,
    updateUserError,
    forgetPasswordSuccess,
    forgetPasswordError,
    saveBusinessAttributesSuccess,
    saveBusinessAttributesError,
    sendDocumentsSuccess,
    sendDocumentsError,
    saveBankAttributesSuccess,
    saveBankAttributesError,
} from './actions';
import AuthService from './service';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = user => {
    if (user) localStorage.setItem('user', JSON.stringify(user));
    else localStorage.removeItem('user');
};
/**
 * Login the user
 * @param {Object} action - dispatched actions
 */
function* login({ payload }) {
        const res = yield call(AuthService.login, payload);
        if (res.status === 200) {
            setSession(res.data);
            yield put(loginUserSuccess(res.data));
        } else {
            yield put(loginUserError(res));
            setSession(null);
        }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload }) {
    const res = yield call(AuthService.registerUser, payload)
    if (res.status === 200) {
        setSession(res.data);
        yield put(registerUserSuccess(res.data))
    } else {
        yield put(registerUserError(res))
    }
}

/**
 * Update the user
 */
function* update({ payload }) {
    const res = yield call(AuthService.updateUser, payload)
    if (res.status === 200) {
        setSession(res.data);
        yield put(updateUserSuccess(res.data))
    } else {
        yield put(updateUserError(res))
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email } }) {
    const res = yield call(AuthService.forgotPassword, email);
    if (res.status === 200) {
        yield put(forgetPasswordSuccess(res.data));
    } else {
        yield put(forgetPasswordError(res));
    }
}

function* saveBankAttributesSaga({ payload }) {
    const res = yield call(AuthService.updateBankAttributes, payload)

    if (res.status === 200) {
        yield put(saveBankAttributesSuccess(res.data));
    } else {
        yield put(saveBankAttributesError(res))
    }
}

function* saveBusinessAttributesSaga({ payload }) {
    const res = yield call(AuthService.updateBusinessAttributes, payload)

    if (res.status === 200) {
        yield put(saveBusinessAttributesSuccess({ ...res.data, silent: payload.silent, first_access: payload.first_access }))
    } else {
        yield put(saveBusinessAttributesError(res))
    }
}

function* sendDocumentsSaga({ payload }) {
    const res = yield call(AuthService.sendDocument, payload)

    if (res.status === 200) {
        yield put(sendDocumentsSuccess(res.data))
    } else {
        yield put(sendDocumentsError(res))
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchUpdateUser() {
    yield takeEvery(UPDATE_USER, update);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchSaveBankAttributes() {
    yield takeEvery(SAVE_BANK_ATTRIBUTES, saveBankAttributesSaga)
}

export function* watchSaveBusinessAttributes() {
    yield takeEvery(SAVE_BUSINESS_ATTRIBUTES, saveBusinessAttributesSaga)
}

export function* watchSendDocuments() {
    yield takeEvery(SEND_DOCUMENTS, sendDocumentsSaga)
}

function* authSaga() {
    yield all([
        fork(watchLoginUser), 
        fork(watchLogoutUser), 
        fork(watchRegisterUser), 
        fork(watchUpdateUser), 
        fork(watchForgetPassword),
        fork(watchSaveBusinessAttributes),
        fork(watchSaveBankAttributes),
        fork(watchSendDocuments),
    ]);
}

export default authSaga;
