import { Api } from '../../helpers/api';
const API = new Api();

class AdventureService {
    getCompanyAdventures(){
        return API.call('/v2/adventure/get-adventure-by-company-independ-of-status', 'get')
    }

    getAdventuresCategories(){
        return API.call('/v1/category/get-categories', 'get')
    }

    getAdventuresTags(){
        return API.call('/v2/tag/get-all-tags', 'get')
    }

    saveDraftAdventure(data){
        return API.call('/v2/adventure/update-adventure', 'post', { adventure: data })
    }

    uploadImageToAws(data){
        return API.call('/v1/image/save-image', 'post', data)
    }

    createAdventure(data){
        return API.call('/v2/adventure/create-adventure', 'post', { adventure: data })
    }
}

export default new AdventureService()