import React from 'react'
import { FormGroup, Label, Input, Row, Col } from 'reactstrap'
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import InputMask from 'react-input-mask';
import { useState } from 'react';
import { useEffect } from 'react';
import Api from '../helpers/api';
import Select from 'react-select';
const validate = (value, ctx, input, cb) => {
    cb(!!value)
}

export const BankFields = ({ bank_number, agency_number, agency_check_number, account_number, account_check_number, owner, document, onChangeFields }) => {
    const [loadingBanks, setLoadingBanks] = useState(false)
    const [banksOptions, setBanksOptions] = useState([])

    useEffect(() => {
        setLoadingBanks(true)
        Api.call('/v2/juno/list-banks', 'get')
            .then(res => {
                setBanksOptions(res.banks.map(bank => ({ value: bank.number, label: `${bank.number} - ${bank.name}` })))
                setLoadingBanks(false)
            })
            .catch(err => setLoadingBanks(false))
    }, [])

    return (
        <AvForm>
            <FormGroup>
                <Label>Número do banco</Label>
                <Select
                    styles={{
                        menu: (provided) =>  ({ ...provided, zIndex: 999 })
                    }}
                    value={banksOptions.find(option => option.value === bank_number )}
                    placeholder="Selecione uma opção"
                    loadingMessage="Carregando...."
                    noOptionsMessage="Sem opções"
                    isLoading={loadingBanks}
                    options={banksOptions}
                    onChange={item => onChangeFields("bank_number", item.value)}/>
            </FormGroup>
            <Row>
                <Col xs={8}>
                    <AvGroup>
                        <Label>Número da agência</Label>
                        <AvInput required value={agency_number} mask="99999999999" maskChar="" tag={[Input, InputMask]}  name="agency_number" onChange={e => onChangeFields("agency_number", e.target.value)} />
                        <AvFeedback>Número de agência inválido</AvFeedback>
                    </AvGroup>
                </Col>
                <Col xs={4}>
                    <AvGroup>
                        <Label>Digito da agência</Label>
                        <AvInput required value={agency_check_number} mask="9" maskChar="" tag={[Input, InputMask]}  validate={{ custom: validate }} name="agency_check_number" onChange={e => onChangeFields("agency_check_number", e.target.value )} />
                        <AvFeedback>Digito inválido</AvFeedback>
                    </AvGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={8}>
                    <AvGroup>
                        <Label>Número da conta</Label>
                        <AvInput required mask="99999999999" maskChar="" tag={[Input, InputMask]}  name="account_number" value={account_number} onChange={e => onChangeFields("account_number", e.target.value)} />
                        <AvFeedback>Número da conta inválido</AvFeedback>
                    </AvGroup>
                </Col>
                <Col xs={4}>
                    <AvGroup>
                        <Label>Digito da conta</Label>
                        <AvInput required mask="9" maskChar="" tag={[Input, InputMask]} name="account_check_number" value={account_check_number} onChange={e => onChangeFields("account_check_number", e.target.value)} />
                        <AvFeedback>Digito da conta inválido</AvFeedback>
                    </AvGroup>
                </Col>
            </Row>
            <AvGroup>
                <Label>Nome do dono da conta</Label>
                <AvInput required value={owner} name="owner" onChange={e => onChangeFields("owner", e.target.value)} />
                <AvFeedback>Nome inválido</AvFeedback>
            </AvGroup>
            <AvGroup>
                <Label>Documento do dono da conta</Label>
                <AvInput required value={document} mask="999999999999999" maskChar="" tag={[Input, InputMask]} name="document" onChange={e => onChangeFields("document", e.target.value)} />
                <AvFeedback>Número de agência invalido</AvFeedback>
            </AvGroup>
        </AvForm>
    )
}
