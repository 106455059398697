
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    CLEAR_FORGET_PASSWORD
} from '../constants';
import { UPDATE_USER_FIELDS, SAVE_BUSINESS_ATTRIBUTES, SAVE_BUSINESS_ATTRIBUTES_SUCCESS, SAVE_BUSINESS_ATTRIBUTES_ERROR, SAVE_BANK_ATTRIBUTES, SAVE_BANK_ATTRIBUTES_SUCCESS, SAVE_BANK_ATTRIBUTES_ERROR, SEND_DOCUMENTS, SEND_DOCUMENTS_SUCCESS, SEND_DOCUMENTS_ERROR, CLEAR_SUCCESS } from './constants';


export const loginUser = (email, password) => ({
    type: LOGIN_USER,
    payload: { email, password },
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const loginUserError = (error) => ({
    type: LOGIN_USER_ERROR,
    payload: error,
});

export const registerUser = (data) => ({
    type: REGISTER_USER,
    payload: data,
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});

export const registerUserError = (error) => ({
    type: REGISTER_USER_ERROR,
    payload: error,
});

export const updateUser = (data) => ({
    type: UPDATE_USER,
    payload: data,
});

export const updateUserSuccess = (user) => ({
    type: UPDATE_USER_SUCCESS,
    payload: user,
});

export const updateUserError = (error) => ({
    type: UPDATE_USER_ERROR,
    payload: error,
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history },
});

export const forgetPassword = (email) => ({
    type: FORGET_PASSWORD,
    payload: { email },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const forgetPasswordError = (error) => ({
    type: FORGET_PASSWORD_ERROR,
    payload: error,
});

export const clearForgetPassword = () => ({
    type: CLEAR_FORGET_PASSWORD
})

export const updateUserFields = (data) => ({
    type: UPDATE_USER_FIELDS,
    payload: data
})

export const saveBusinessAttributes = (data) => ({
    type: SAVE_BUSINESS_ATTRIBUTES,
    payload: data
})

export const saveBusinessAttributesSuccess = (data) => ({
    type: SAVE_BUSINESS_ATTRIBUTES_SUCCESS,
    payload: data
})

export const saveBusinessAttributesError = (err) => ({
    type: SAVE_BUSINESS_ATTRIBUTES_ERROR,
    payload: err
})

export const saveBankAttributes = (data) => ({
    type: SAVE_BANK_ATTRIBUTES,
    payload: data
})

export const saveBankAttributesSuccess = (data) => ({
    type: SAVE_BANK_ATTRIBUTES_SUCCESS,
    payload: data
})

export const saveBankAttributesError = (err) => ({
    type: SAVE_BANK_ATTRIBUTES_ERROR,
    payload: err
})

export const sendDocuments = (data) => ({
    type: SEND_DOCUMENTS,
    payload: data
})

export const sendDocumentsSuccess = (data) => ({
    type: SEND_DOCUMENTS_SUCCESS,
    payload: data
})

export const sendDocumentsError = (err) => ({
    type: SEND_DOCUMENTS_ERROR,
    payload: err
})

export const clearSuccess = () => ({
    type: CLEAR_SUCCESS
})