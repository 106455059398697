import { v4 as uuid4 } from 'uuid'

export class Reserve {
    constructor(json={}){
        this.id = json.id || uuid4()
        this.pk_reserve = json.pk_reserve || null
        this.request_id = json.request_id || null
        this.fk_request = json.fk_request || null
        this.adventure_id = json.adventure_id || null
        this.fk_adventure = json.fk_adventure || null
        this.payment_id = json.payment_id || null
        this.fk_payment = json.fk_payment || null
        this.package_id = json.package_id || null
        this.fk_package = json.fk_package || null
        this.user_id = json.user_id || null
        this.fk_user = json.fk_user || null
        this.type = "reserve"

        this.created_at = json.created_at || ''
        this.is_deleted = json.is_deleted || false
        this.name = json.name || ''
        this.phone = json.phone || ''
        this.email = json.email || ''
        this.tags = json.tags || []
        this.custom_fields = json.custom_fields ? json.custom_fields.map(field => ({ ...field, id: uuid4() })) : []
    }
}

export const denormalizeReserve = (reserve, entities) => {
    reserve.adventure = { ...entities.adventure.byId[reserve.adventure_id] }
    reserve.payment = { ...entities.payment.byId[reserve.payment_id] }
    reserve.package = { ...entities.package.byId[reserve.package_id] }
    reserve.user = { ...entities.user.byId[reserve.user_id] }
    reserve.request = { ...entities.request.byId[reserve.request_id] }
    return { ...reserve }
}

export const normalizeReserve = (reserve) => {
    reserve.package = undefined
    reserve.payment = undefined
    reserve.adventure = undefined
    reserve.user = undefined
    reserve.request = undefined
    return { ...reserve }
}