import { BANNER_TYPE, THUMBNAIL_TYPE, IMAGE_TYPE } from "../image/constants"

export const addScriptSliceById = (state, action) => {
    const { adventure_id } = action.payload
    if (adventure_id && !!state[adventure_id]) {
        return { 
            ...state, 
            [adventure_id]: { 
                ...state[adventure_id], 
                scripts: [ ...state[adventure_id].scripts, action.payload.id ] 
            } 
        }
    } 

    return state
}

export const removeScriptSliceById = (state, action) => {
    return Object
            .keys(state)
            .reduce((prevState, curAdvId) => ({
                 ...prevState, 
                 [curAdvId]: { 
                        ...state[curAdvId], 
                        scripts: state[curAdvId].scripts
                                    .reduce((prevScripts, curScriptId) => curScriptId !== action.payload.id ? [ ...prevScripts, curScriptId] : prevScripts, []) 
                    }
            }), {})
}

export const addFAQSliceById = (state, action) => {
    const { adventure_id } = action.payload
    if (adventure_id && !!state[adventure_id]) {
        return { 
            ...state, 
            [adventure_id]: { 
                ...state[adventure_id], 
                FAQ: [ ...state[adventure_id].FAQ, action.payload.id ] 
            } 
        }
    } 

    return state
}

export const removeFAQSliceById = (state, action) => {
    return Object
            .keys(state)
            .reduce((prevState, curAdvId) => ({
                 ...prevState, 
                 [curAdvId]: { 
                        ...state[curAdvId], 
                        FAQ: state[curAdvId].FAQ
                                    .reduce((prevFAQ, curFAQId) => curFAQId !== action.payload.id ? [ ...prevFAQ, curFAQId] : prevFAQ, []) 
                    }
            }), {})
}

export const addPackageSliceById = (state, action) => {
    const { adventure_id } = action.payload
    if (adventure_id && !!state[adventure_id]) {
        return { 
            ...state, 
            [adventure_id]: { 
                ...state[adventure_id], 
                packages: [ ...state[adventure_id].packages, action.payload.id ] 
            } 
        }
    } 

    return state
}

export const removePackageSliceById = (state, action) => {
    return Object
            .keys(state)
            .reduce((prevState, curAdvId) => ({
                 ...prevState, 
                 [curAdvId]: { 
                        ...state[curAdvId], 
                        packages: state[curAdvId].packages
                                    .reduce((prevPackages, curPackageId) => curPackageId !== action.payload.id ? [ ...prevPackages, curPackageId] : prevPackages, []) 
                    }
            }), {})
}

export const addScheduleRuleSliceById = (state, action) => {
    const { adventure_id } = action.payload
    if (adventure_id && !!state[adventure_id]) {
        return { 
            ...state, 
            [adventure_id]: { 
                ...state[adventure_id], 
                scheduleRules: [ ...state[adventure_id].scheduleRules, action.payload.id ] 
            } 
        }
    } 

    return state
}

export const removeScheduleRuleSliceById = (state, action) => {
    return Object
            .keys(state)
            .reduce((prevState, curAdvId) => ({
                 ...prevState, 
                 [curAdvId]: { 
                        ...state[curAdvId], 
                        scheduleRules: state[curAdvId].scheduleRules
                                        .reduce((prevScheduleRules, curScheduleRulesId) => curScheduleRulesId !== action.payload.id ? [ ...prevScheduleRules, curScheduleRulesId] : prevScheduleRules, []) 
                    }
            }), {})
}

export const addImageSliceById = (state, action) => {
    const { adventure_id } = action.payload
    if (adventure_id && !!state[adventure_id]) {
        const newState = { 
            ...state, 
            [adventure_id]: { 
                ...state[adventure_id]
            } 
        }
        switch (action.payload.type) {
            case BANNER_TYPE:
                newState[adventure_id].banner = action.payload.id
                return newState
            case THUMBNAIL_TYPE:
                newState[adventure_id].image = action.payload.id
                return newState;
            case IMAGE_TYPE:
                newState[adventure_id].images = [ ...newState[adventure_id].images, action.payload.id ]
                return newState;
            default:
                return state;
        }
    } 

    return state
}

export const removeImageSliceById = (state, action) => {
    return Object
            .keys(state)
            .reduce((prevState, curAdvId) => ({
                 ...prevState, 
                 [curAdvId]: { 
                        ...state[curAdvId], 
                        image: state[curAdvId].image !== action.payload.id ? state[curAdvId].image : '',
                        banner: state[curAdvId].banner !== action.payload.id ? state[curAdvId].banner : '',
                        images: state[curAdvId].images
                                    .reduce((prevImages, curImageId) => curImageId !== action.payload.id ? [ ...prevImages, curImageId] : prevImages, []) 
                    }
            }), {})
}

export const addRequestCustomFieldSliceById = (state, action) => {
    const { adventure_id } = action.payload
    if (adventure_id && !!state[adventure_id]) {
        return { 
            ...state, 
            [adventure_id]: { 
                ...state[adventure_id], 
                requestCustomFields: [ ...state[adventure_id].requestCustomFields, action.payload.id ] 
            } 
        }
    } 

    return state
}

export const removeRequestCustomFieldSliceById = (state, action) => {
    return Object
            .keys(state)
            .reduce((prevState, curAdvId) => ({
                 ...prevState, 
                 [curAdvId]: { 
                        ...state[curAdvId], 
                        requestCustomFields: state[curAdvId].requestCustomFields
                                    .reduce((prevRequestCustomFields, curRQCFId) => curRQCFId !== action.payload.id ? [ ...prevRequestCustomFields, curRQCFId] : prevRequestCustomFields, []) 
                    }
            }), {})
}

export const addReserveCustomFieldSliceById = (state, action) => {
    const { adventure_id } = action.payload
    if (adventure_id && !!state[adventure_id]) {
        return { 
            ...state, 
            [adventure_id]: { 
                ...state[adventure_id], 
                reserveCustomFields: [ ...state[adventure_id].reserveCustomFields, action.payload.id ] 
            } 
        }
    } 

    return state
}

export const removeReserveCustomFieldSliceById = (state, action) => {
    return Object
            .keys(state)
            .reduce((prevState, curAdvId) => ({
                 ...prevState, 
                 [curAdvId]: { 
                        ...state[curAdvId], 
                        reserveCustomFields: state[curAdvId].reserveCustomFields
                                    .reduce((prevReserveCustomFields, curRSCFId) => curRSCFId !== action.payload.id ? [ ...prevReserveCustomFields, curRSCFId] : prevReserveCustomFields, []) 
                    }
            }), {})
}

export const addRequestSliceById = (state, action) => {
    const { adventure_id } = action.payload
    if (adventure_id && !!state[adventure_id]) {
        return { 
            ...state, 
            [adventure_id]: { 
                ...state[adventure_id], 
                requests: [ ...state[adventure_id].requests, action.payload.id ] 
            } 
        }
    } 

    return state
}

export const removeRequestSliceById = (state, action) => {
    return Object
            .keys(state)
            .reduce((prevState, curAdvId) => ({
                 ...prevState, 
                 [curAdvId]: { 
                        ...state[curAdvId], 
                        requests: state[curAdvId].requests
                                    .reduce((prevRequests, curReqId) => curReqId !== action.payload.id ? [ ...prevRequests, curReqId] : prevRequests, []) 
                    }
            }), {})
}