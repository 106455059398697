export const removeReserveSliceById = (state, action) => {
    return Object
            .keys(state)
            .reduce((prevState, curReqId) => ({
                 ...prevState, 
                 [curReqId]: { 
                        ...state[curReqId], 
                        reserves: state[curReqId].reserves
                                    .reduce((prevReserves, curReserveId) => curReserveId !== action.payload.id ? [ ...prevReserves, curReserveId] : prevReserves, []) 
                    }
            }), {})
}

export const addReserveSliceById = (state, action) => {
    const { request_id } = action.payload
    if (request_id && !!state[request_id]) {
        return { 
            ...state, 
            [request_id]: { 
                ...state[request_id], 
                reserves: [ ...state[request_id].reserves, action.payload.id ] 
            } 
        }
    } 

    return state
}