import { 
    FETCH_COMPANY_ADVENTURES, 
    FETCH_COMPANY_ADVENTURES_SUCCESS, 
    FETCH_COMPANY_ADVENTURES_ERROR, 
    SAVE_DRAFT_ADVENTURE,
    SAVE_DRAFT_ADVENTURE_SUCCESS,
    SAVE_DRAFT_ADVENTURE_ERROR,
    SELECT_ADVENTURE,
    SELECT_ADVENTURE_ERROR,
    FETCH_ADVENTURES_CATEGORIES,
    FETCH_ADVENTURES_CATEGORIES_SUCCESS,
    FETCH_ADVENTURES_CATEGORIES_ERROR,
    UPLOAD_IMAGE_FILE_TO_AWS,
    UPLOAD_IMAGE_FILE_TO_AWS_SUCCESS,
    UPLOAD_IMAGE_FILE_TO_AWS_ERROR,
    CLEAR_ERROR,
    INIT_ADVENTURE_CREATION,
    FETCH_ADVENTURES_TAGS, 
    FETCH_ADVENTURES_TAGS_SUCCESS, 
    FETCH_ADVENTURES_TAGS_ERROR,
    CLEAR_CURRENT_ADVENTURE,
    REFRESH_ADVENTURES
} from "../constants";
import { CREATE_NEW_ADVENTURE, CREATE_NEW_ADVENTURE_SUCCESS, CREATE_NEW_ADVENTURE_ERROR } from "./constants";
import { LOGOUT_USER } from "../auth/constants";

const initialState = {
    adventures: [],
    categories: [],
    tags: [],
    error: null,
    loadingAdventures: false,
    loadingCategories: false,
    loadingSaveAdventure: false,
    loadingUploadImage: false,
    currentAdventure: null
}

const Adventures = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANY_ADVENTURES:
            return { ...state, loadingAdventures: true }

        case FETCH_COMPANY_ADVENTURES_SUCCESS:
            return { ...state, loadingAdventures: false, adventures: [ ...action.payload ] }

        case FETCH_COMPANY_ADVENTURES_ERROR:
            return { ...state, loadingAdventures: false, error: action.payload }
        
        case REFRESH_ADVENTURES:
            return { ...state, adventures: [] }

        case FETCH_ADVENTURES_CATEGORIES:
            return { ...state, loadingCategories: true }

        case FETCH_ADVENTURES_CATEGORIES_SUCCESS:
            return { ...state, loadingCategories: false, categories: [ ...action.payload ] }

        case FETCH_ADVENTURES_CATEGORIES_ERROR:
            return { ...state, loadingCategories: false, error: action.payload }

        case FETCH_ADVENTURES_TAGS:
            return { ...state, loadingTags: true }

        case FETCH_ADVENTURES_TAGS_SUCCESS:
            return { ...state, loadingTags: false, tags: [ ...action.payload ]}

        case FETCH_ADVENTURES_TAGS_ERROR:
            return { ...state, loadingTags: false, error: { ...action.payload }}

        case SAVE_DRAFT_ADVENTURE:
            return { ...state, loadingSaveAdventure: true }

        case SAVE_DRAFT_ADVENTURE_SUCCESS:
            return { ...state, loadingSaveAdventure: false }

        case SAVE_DRAFT_ADVENTURE_ERROR:
            return { ...state, loadingSaveAdventure: false, error: action.payload }

        case UPLOAD_IMAGE_FILE_TO_AWS:
            return { ...state, loadingUploadImage: true }

        case UPLOAD_IMAGE_FILE_TO_AWS_SUCCESS:
            return { ...state, loadingUploadImage: false }

        case UPLOAD_IMAGE_FILE_TO_AWS_ERROR:
            return { ...state, loadingUploadImage: false, error: action.payload }

        case INIT_ADVENTURE_CREATION:
            return { ...state, currentAdventure: action.payload.id }

        case SELECT_ADVENTURE:
            return { ...state, currentAdventure: action.payload.id }
        
        case CLEAR_CURRENT_ADVENTURE:
            return { ...state, currentAdventure: null }
        
        case SELECT_ADVENTURE_ERROR:
            return { ...state, currentAdventure: null, error: action.payload }

        case CLEAR_ERROR:
            return { ...state, error: null }
        
        case CREATE_NEW_ADVENTURE:
            return { ...state, loadingSaveAdventure: true }
        
        case CREATE_NEW_ADVENTURE_SUCCESS:
            return { ...state, loadingSaveAdventure: false }

        case CREATE_NEW_ADVENTURE_ERROR:
            return { ...state, loadingSaveAdventure: false, error: action.payload }
        case LOGOUT_USER:
            return initialState
        default:
            return state;
    }
}

export default Adventures