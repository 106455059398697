
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import reservationSaga from './reservation/saga'
import adventuresSaga from './adventures/saga'
import financesSaga from './finances/saga';
import vouchersSaga from './vouchers/saga'

export default function* rootSaga(getState) {
    yield all([
        authSaga(), 
        layoutSaga(), 
        appMenuSaga(), 
        reservationSaga(), 
        adventuresSaga(),
        financesSaga(),
        vouchersSaga()
    ]);
}
