import React from 'react'
import { Alert } from 'reactstrap'
import { useEffect } from 'react'

export const Toast = ({ message, clear, color }) => {
    useEffect(() => {
        setTimeout(() => {
            clear()
        }, 2000)
    }, [clear])
    
    return (
        <div style={{ position: 'fixed', bottom: '5px', left: '0', right: '0', zIndex: '5', margin: 'auto', maxWidth: '35vw' }}>
            <Alert color={color} isOpen={!!message} toggle={() => clear()}>{message}</Alert>
        </div>
    )
}
