import {
    FETCH_FINANCES_BALANCES,
    FETCH_FINANCES_BALANCES_SUCCESS,
    FETCH_FINANCES_BALANCES_ERROR,
    FETCH_FINANCES_EXTRACT_MONTH,
    FETCH_FINANCES_EXTRACT_MONTH_SUCCESS,
    FETCH_FINANCES_EXTRACT_MONTH_ERROR,
    FETCH_FINANCES_TRANSFER,
    FETCH_FINANCES_TRANSFER_SUCCESS,
    FETCH_FINANCES_TRANSFER_ERROR
} from './constants';

export const fetchFinancesBalances = () => ({
    type: FETCH_FINANCES_BALANCES
})

export const fetchFinancesBalancesSuccess = (data) => ({
    type: FETCH_FINANCES_BALANCES_SUCCESS,
    payload: data
})

export const fetchFinancesBalancesError = (err) => ({
    type: FETCH_FINANCES_BALANCES_ERROR,
    payload: err
})

export const fetchFinancesExtractMonth = (data) => ({
    type: FETCH_FINANCES_EXTRACT_MONTH,
    payload: data
})

export const fetchFinancesExtractMonthSuccess = (data) => ({
    type: FETCH_FINANCES_EXTRACT_MONTH_SUCCESS,
    payload: data
})

export const fetchFinancesExtractMonthError = (err) => ({
    type: FETCH_FINANCES_EXTRACT_MONTH_ERROR,
    payload: err
})

export const fetchFinancesTransfer = (data) => ({
    type: FETCH_FINANCES_TRANSFER,
    payload: data
})

export const fetchFinancesTransferSuccess = (data) => ({
    type: FETCH_FINANCES_TRANSFER_SUCCESS,
    payload: data
})

export const fetchFinancesTransferError = (err) => ({
    type: FETCH_FINANCES_TRANSFER_ERROR,
    payload: err
})
