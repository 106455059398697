import { v4 as uuid4 } from 'uuid';
import { Script, ScriptItem, FAQ } from './index'
import { Package } from './package';
import { ScheduleRule } from './scheduleRule';
import { Image } from './image';
import { RequestCustomField } from './requestCustomField';
import { ReserveCustomField } from './reserveCustomField';
import { Tag } from './tag';
import { CANCEL_RULES_TYPES } from '../redux/constants';
import { getRandomColor, getRandomColorFromNumber } from '../helpers/utils';

export class Adventure {
    constructor(json = {}){
        this.id = json.id || uuid4();
        this.pk_adventure = json.pk_adventure || null
        this.color = json.pk_adventure ? getRandomColorFromNumber(json.pk_adventure) : getRandomColor()
        this.image = json.image || ''
        this.banner = json.banner || ''
        this.title = json.title || ''
        this.description = json.description || ''
        this.price = json.price || ''
        this.duration = json.duration || '00:00'
        this.long_description = json.long_description || ''
        this.min = json.min || ''
        this.max = json.max || ''
        this.url_name = json.url_name || ''
        this.lat = json.lat || ''
        this.lng = json.lng || ''
        this.street = json.street || ''
        this.number = json.number || ''
        this.complement = json.complement || ''
        this.city = json.city || ''
        this.neighborhood = json.neighborhood || ''
        this.cep = json.cep || ''
        this.state = json.state || ''
        this.fk_company = json.fk_company || ''
        this.only_public = json.only_public === undefined ? true : json.only_public 
        this.status = json.status || ''
        this.online_scheduling = json.online_scheduling || false
        this.same_locale_and_meeting_point = json.same_locale_and_meeting_point || ''
        this.meeting_point_lat = json.meeting_point_lat || ''
        this.meeting_point_lng = json.meeting_point_lng || ''
        this.meeting_point_cep = json.meeting_point_cep || ''
        this.meeting_point_complement = json.meeting_point_complement || ''
        this.meeting_point_street = json.meeting_point_street || ''
        this.meeting_point_number = json.meeting_point_number || ''
        this.meeting_point_city = json.meeting_point_city || ''
        this.meeting_point_state = json.meeting_point_state || ''
        this.meeting_point_neighborhood = json.meeting_point_neighborhood || ''
        this.meeting_point_district = json.meeting_point_district || ''
        this.block_request_hours_before = json.block_request_hours_before || 72
        this.block_participation_hours_before = json.block_participation_hours_before || 72
        this.google_calendar_id = json.google_calendar_id || ''
        this.fk_category = json.fk_category || null
        this.fk_region = json.fk_region || null
        this.voucher_enabled = json.voucher_enabled || false
        this.terms = json.terms || ''
        this.voucher_expiration_days = json.voucher_expiration_days || 90
        this.local_payment_enabled = json.local_payment_enabled || false
        this.local_payment_methods = json.local_payment_methods || false
        this.credit_card_enabled = json.credit_card_enabled === undefined ? true : json.credit_card_enabled
        this.boleto_enabled = json.boleto_enabled === undefined ? false : json.boleto_enabled
        this.cancel_rules = json.cancel_rules || CANCEL_RULES_TYPES.COMPLETE
        this.fill_all_reserves = json.fill_all_reserves || false
        this.video = json.video || ''
        this.tags = json.tags || []
        this.scripts = json.scripts || []
        this.FAQ = json.FAQ || []
        this.packages = json.packages || []
        this.scheduleRules = json.scheduleRules || []
        this.images = json.images || []
        this.requestCustomFields = json.requestCustomFields || []
        this.reserveCustomFields = json.reserveCustomFields || []
        this.sell_type = json.sell_type || ''
        this.voucher_terms = json.voucher_terms || ''


        this.requests = json.requests || []
    }
}

/**
 * Função para transforma uma aventura normalizada em um objeto completo
 * @param {Object} adv Entity normalizada
 * @param {Object} entities Store com as entitys
 */
export function denormalizeAdventure(adv, entities){
    adv.image = !!adv.image ? entities.image.byId[adv.image] : ''
    adv.banner = !!adv.banner ? entities.image.byId[adv.banner] : ''
    adv.scripts = adv.scripts.map(script_id => {
        const script = new Script(entities.script.byId[script_id])
        script.items = script.items.map(item_id => new ScriptItem(entities.scriptItem.byId[item_id]))
        return script
    })
    adv.FAQ = adv.FAQ.map(faq_id => new FAQ(entities.FAQ.byId[faq_id]))
    adv.packages = adv.packages.map(pack_id => new Package(entities.package.byId[pack_id]))
    adv.scheduleRules = adv.scheduleRules.map(srId => new ScheduleRule(entities.scheduleRule.byId[srId]))
    adv.images = adv.images.map(imgId => new Image(entities.image.byId[imgId]))
    adv.requestCustomFields = adv.requestCustomFields.map(imgId => new RequestCustomField(entities.requestCustomField.byId[imgId]))
    adv.reserveCustomFields = adv.reserveCustomFields.map(imgId => new ReserveCustomField(entities.reserveCustomField.byId[imgId]))
    adv.tags = adv.tags.map(tagId => new Tag(entities.tag.byId[tagId]))
    return adv
}

/**
 * Função para normalizar de volta a aventura antes de enviar pra store
 * @param {Object} adv Aventura não normalizada
 */
export function normalizeAdventure(adv){
    adv.image = !!adv.image ? adv.image.id : ''
    adv.banner = !!adv.banner ? adv.banner.id : ''
    adv.scripts = adv.scripts.map(script => script.id)
    adv.FAQ = adv.FAQ.map(faq => faq.id)
    adv.packages = adv.packages.map(pack => pack.id)
    adv.scheduleRules = adv.scheduleRules.map(rule => rule.id)
    adv.images = adv.images.map(img => img.id)
    adv.requestCustomFields = adv.requestCustomFields.map(rqcf => rqcf.id)
    adv.reserveCustomFields = adv.reserveCustomFields.map(rscf => rscf.id)
    adv.tags = adv.tags.map(tag => tag.id)
    return { ...adv }
}

export const adventureTypes = [
    { value: 'voucher', label: 'Voucher' },
    { value: 'event', label: 'Evento' },
    { value: 'trip', label: 'Viagem de experiência' },
    { value: 'daily', label: 'Atividades diárias' },
    { value: 'schedule', label: 'Atividades com vários horários' }
]