import { Api } from '../../helpers/api';
const API = new Api()

class VouchersService {
    getCompanyPurchases(){
        return API.call('/v2/company/get-purchases', 'get')
    }

    saveVoucher(data) {
        return API.call('/v2/voucher/create-company-voucher', 'post', data)
    }

    consumeVoucher(voucher) {
        return API.call('/v1/request/validate-voucher', 'post', { pkVoucher: voucher.pk_voucher })
    }

    cancelVoucher(voucher) {
        return API.call('/v1/request/cancel-voucher', 'post', { pkVoucher: voucher.pk_voucher })
    }

    saveVoucherInfo(voucher) {
        return API.call('/v2/voucher/update-voucher-info', 'post', voucher)
    }
}

export default new VouchersService()