import {
    FETCH_FINANCES_BALANCES,
    FETCH_FINANCES_BALANCES_SUCCESS,
    FETCH_FINANCES_BALANCES_ERROR,
    FETCH_FINANCES_EXTRACT_MONTH,
    FETCH_FINANCES_EXTRACT_MONTH_SUCCESS,
    FETCH_FINANCES_EXTRACT_MONTH_ERROR,
    FETCH_FINANCES_TRANSFER,
    FETCH_FINANCES_TRANSFER_SUCCESS,
    FETCH_FINANCES_TRANSFER_ERROR
} from './constants';
import { LOGOUT_USER } from '../auth/constants';

const initialState = {
    balances: { current: 0, future: 0, unavailable: 0 },
    extractMonth: [],
    transference: [],
    loadingBalances: false,
    loadingTransfer: false,
    loadingExtractMonth: false
}

const Finances = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_FINANCES_BALANCES:
            return { ...state, loadingBalances: true }
        
        case FETCH_FINANCES_BALANCES_SUCCESS:
            return { ...state, loadingBalances: false, balances: action.payload }

        case FETCH_FINANCES_BALANCES_ERROR:
            return { ...state, loadingBalances: false, error: action.payload }
        
        case FETCH_FINANCES_EXTRACT_MONTH:
            return { ...state, loadingExtractMonth: action.payload.download === 0, data: action.payload }

        case FETCH_FINANCES_EXTRACT_MONTH_SUCCESS:
            return { ...state, loadingExtractMonth: false, extractMonth: action.payload }

        case FETCH_FINANCES_EXTRACT_MONTH_ERROR:
            return { ...state, loadingExtractMonth: false, error: action.payload }

        case FETCH_FINANCES_TRANSFER:
            return { ...state, loadingTransfer: true, data: action.payload }

        case FETCH_FINANCES_TRANSFER_SUCCESS:
            return { ...state, loadingTransfer: false, transference: action.payload }

        case FETCH_FINANCES_TRANSFER_ERROR:
            return { ...state, loadingTransfer: false, error: action.payload }
        case LOGOUT_USER:
            return initialState
        default:
            return state;
    }
}

export default Finances

