import React from 'react'
import { Label, Row, Col, FormText, Button, Input, Container } from 'reactstrap'
import { Check } from 'react-feather'
import Loader from '../../components/Loader'
import InputMask from 'react-input-mask';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
const validate = (value, ctx, input, cb) => {
    cb(!!value)
}

export const BusinessInfo = (props) => {
    const { loading, type } = props
    return (
        <Container>
            {loading &&  <Loader />}
            {type === "cpf" ?
            <CPFFields {...props} />
            :
            <CNPJFields {...props} />
            }
        </Container>
    )
}

export const CPFFields = ({ name, cpf, birthday, business_phone, address, number, district, complement, cep, city, state, onChangeField, onSave, noSave = false, fullWidth = false }) => {
    return (
        <AvForm>
            <Row>
                <Col md={fullWidth ? 12 : 6}>
                    <h4>Titular do contrato</h4>
                    <AvGroup>
                        <Label>Nome Completo</Label>
                        <AvInput placeholder="Ex: Fulano da Silva" value={name} name="name" required onChange={e => onChangeField("name", e.target.value)} />
                        <AvFeedback>Campo obrigatório</AvFeedback>
                    </AvGroup>
                    <AvGroup>
                        <Label>CPF</Label>
                        <AvInput placeholder="XXXXXXXXXXX" mask="99999999999" maskChar="" tag={[Input, InputMask]} value={cpf} name="cpf" validate={{ custom: validate, minLength: { value: 11 } }} onChange={e => onChangeField("cpf", e.target.value)} />
                        <AvFeedback>CPF inválido</AvFeedback>
                    </AvGroup>
                    <AvGroup>
                        <Label>Data de nascimento</Label>
                        <AvInput placeholder="dd/MM/AAAA" type="date" name="birthdat" validate={{ custom: validate, date: true }} value={birthday} onChange={e => onChangeField("birthday", e.target.value)} />
                        <AvFeedback>Data inválida</AvFeedback>
                    </AvGroup>
                    <h4>Endereço</h4>
                    <AvGroup>
                        <Label>CEP</Label>
                        <AvInput placeholder="XXXXXXXX" mask="99999999" maskChar="" tag={[Input, InputMask]}  name="cep" validate={{ custom: validate, minLength: { value: 8 } }} value={cep} onChange={e => onChangeField("cep", e.target.value)} />
                        <AvFeedback>CEP inválido</AvFeedback>
                    </AvGroup>
                    <Row>
                        <Col xs={8}>
                            <AvGroup>
                                <Label>Endereço</Label>
                                <AvInput placeholder="Ex: Rua das Flores" name="address" validate={{ custom: validate }} value={address} onChange={e => onChangeField("address", e.target.value)} />
                                <AvFeedback>Campo obrigatório</AvFeedback>
                            </AvGroup>
                        </Col>
                        <Col xs={4}>
                            <AvGroup>
                                <Label>Numero</Label>
                                <AvInput placeholder="21" mask="999999" maskChar="" tag={[Input, InputMask]}  name="address-number" validate={{ custom: validate }} value={number} onChange={e => onChangeField("number", e.target.value)} />
                                <AvFeedback>Número inválido</AvFeedback>
                            </AvGroup>
                        </Col>
                    </Row>
                    <AvGroup>
                        <Label>Complemento</Label>
                        <AvInput placeholder="Ex: Apt 2" value={complement} name="complement" onChange={e => onChangeField("complement", e.target.value)} />
                    </AvGroup>
                    <AvGroup>
                        <Label>Bairro</Label>
                        <AvInput placeholder="Ex: Centro" value={district}  name="district" validate={{ custom: validate }} onChange={e => onChangeField("district", e.target.value)} />
                        <AvFeedback>Campo obrigatório</AvFeedback>
                    </AvGroup>
                    <Row>
                        <Col>
                            <AvGroup>
                                <Label>Cidade</Label>
                                <AvInput placeholder="Ex: São Paulo" name="city" validate={{ custom: validate }} value={city} onChange={e => onChangeField("city", e.target.value)} />
                                <AvFeedback>Campo obrigatório</AvFeedback>
                            </AvGroup>
                        </Col>
                        <Col>
                            <AvGroup>
                                <Label>Estado</Label>
                                <AvInput placeholder="Ex: SP"  mask="aa" maskChar="" tag={[Input, InputMask]} name="state" validate={{ custom: validate, minLength: { value: 2 } }} value={state} onChange={e => onChangeField("state", e.target.value)} />
                                <AvFeedback>Campo obrigatório</AvFeedback>
                            </AvGroup>
                        </Col>
                    </Row>
                    <h4>Informações de contato</h4>
                    <AvGroup>
                        <Label>Telefone principal</Label>
                        <AvInput placeholder="(DD) XXXXXX-XXXX" mask="(99) 99999-9999" maskChar="" tag={[Input, InputMask]}  name="business_phone" validate={{ custom: validate, minLength: { value: 15 } }} value={business_phone} onChange={e => onChangeField("business_phone", e.target.value)} />
                        <FormText>Este será o telefone para assuntos administrativos</FormText>
                        <AvFeedback>Telefone inválido</AvFeedback>
                    </AvGroup>
                    {!noSave && <Button className="float-right" color="primary" onClick={() => onSave()} ><Check /> Salvar</Button>}
                </Col>
            </Row>
        </AvForm>
    )
}

export const CNPJFields = ({ name, cnpj, social_reason, cpf, business_phone, birthday, address, number, district, complement, cep, city, state, onChangeField, onSave, noSave = false, fullWidth = false }) => {
    return (
        <AvForm>
            <Row>
                <Col md={fullWidth ? 12 : 6}>
                    <h4>Titular do contrato</h4>
                    <AvGroup>
                        <Label>CNPJ</Label>
                        <AvInput placeholder="XXXXXXXXXXXXXX" mask="99999999999999" maskChar="" tag={[Input, InputMask]} name="cnpj" validate={{ custom: validate, minLength: { value: 14 } }} value={cnpj} onChange={e => onChangeField("cnpj", e.target.value)} />
                        <AvFeedback>CNPJ inválido</AvFeedback>
                    </AvGroup>
                    <AvGroup>
                        <Label>Razão Social</Label>
                        <AvInput placeholder="Ex: Fulano LTDA" name="social_reason" validate={{ custom: validate}} value={social_reason} onChange={e => onChangeField("social_reason", e.target.value)} />
                        <AvFeedback>Campo obrigatório</AvFeedback>
                    </AvGroup>
                    <h4>Endereço da empresa</h4>
                    <AvGroup>
                        <Label>CEP</Label>
                        <AvInput placeholder="XXXXXXXX" mask="99999999" maskChar="" tag={[Input, InputMask]}  name="cep" validate={{ custom: validate, minLength: { value: 8 } }} value={cep} onChange={e => onChangeField("cep", e.target.value)} />
                        <AvFeedback>CEP inválido</AvFeedback>
                    </AvGroup>
                    <Row>
                        <Col xs={8}>
                            <AvGroup>
                                <Label>Endereço</Label>
                                <AvInput placeholder="Ex: Rua das Flores" name="address" validate={{ custom: validate }} value={address} onChange={e => onChangeField("address", e.target.value)} />
                                <AvFeedback>Campo obrigatório</AvFeedback>
                            </AvGroup>
                        </Col>
                        <Col xs={4}>
                            <AvGroup>
                                <Label>Numero</Label>
                                <AvInput placeholder="21" mask="999999" maskChar="" tag={[Input, InputMask]}  name="address-number" validate={{ custom: validate }} value={number} onChange={e => onChangeField("number", e.target.value)} />
                                <AvFeedback>Número inválido</AvFeedback>
                            </AvGroup>
                        </Col>
                    </Row>
                    <AvGroup>
                        <Label>Complemento</Label>
                        <AvInput placeholder="Ex: Apt 2" value={complement} name="complement" onChange={e => onChangeField("complement", e.target.value)} />
                    </AvGroup>
                    <AvGroup>
                        <Label>Bairro</Label>
                        <AvInput placeholder="Ex: Centro" value={district}  name="district" validate={{ custom: validate }} onChange={e => onChangeField("district", e.target.value)} />
                        <AvFeedback>Campo obrigatório</AvFeedback>
                    </AvGroup>
                    <Row>
                        <Col>
                            <AvGroup>
                                <Label>Cidade</Label>
                                <AvInput placeholder="Ex: São Paulo" name="city" validate={{ custom: validate }} value={city} onChange={e => onChangeField("city", e.target.value)} />
                                <AvFeedback>Campo obrigatório</AvFeedback>
                            </AvGroup>
                        </Col>
                        <Col>
                            <AvGroup>
                                <Label>Estado</Label>
                                <AvInput placeholder="Ex: SP" mask="aa" maskChar="" tag={[Input, InputMask]}  name="state" validate={{ custom: validate, minLength: { value: 2 }, maxLength: { value: 2 } }} value={state} onChange={e => onChangeField("state", e.target.value)} />
                                <AvFeedback>Campo obrigatório</AvFeedback>
                            </AvGroup>
                        </Col>
                    </Row>
                    <h4>Responsavel legal</h4>
                    <AvGroup>
                        <Label>Nome Completo</Label>
                        <AvInput placeholder="Ex: Fulano da Silva" value={name} name="name" validate={{ custom: validate }} onChange={e => onChangeField("name", e.target.value)} />
                        <AvFeedback>Campo obrigatório</AvFeedback>
                    </AvGroup>
                    <AvGroup>
                        <Label>CPF</Label>
                        <AvInput placeholder="XXXXXXXXXXX" mask="99999999999" maskChar="" tag={[Input, InputMask]} value={cpf} name="cpf" validate={{ custom: validate, minLength: { value: 11 } }} onChange={e => onChangeField("cpf", e.target.value)} />
                        <AvFeedback>CPF inválido</AvFeedback>
                    </AvGroup>
                    <AvGroup>
                        <Label>Data de nascimento</Label>
                        <AvInput placeholder="dd/MM/AAAA" type="date" name="birthdat" validate={{ custom: validate, date: true }} value={birthday} onChange={e => onChangeField("birthday", e.target.value)} />
                        <AvFeedback>Data inválida</AvFeedback>
                    </AvGroup>
                    <h4>Informações de contato</h4>
                    <AvGroup>
                        <Label>Telefone principal</Label>
                        <AvInput placeholder="(DD) XXXXXX-XXXX" mask="(99) 99999-9999" maskChar="" tag={[Input, InputMask]}  name="business_phone" validate={{ custom: validate, minLength: { value: 15 } }} value={business_phone} onChange={e => onChangeField("business_phone", e.target.value)} />
                        <FormText>Este será o telefone para assuntos administrativos</FormText>
                        <AvFeedback>Telefone inválido</AvFeedback>
                    </AvGroup>
                    {!noSave && <Button className="float-right" color="primary" onClick={() => onSave()} ><Check /> Salvar</Button> }
                </Col>
            </Row>
        </AvForm>
    )
}
