import {
    FETCH_COMPANY_PURCHASES,
    FETCH_COMPANY_PURCHASES_SUCCESS,
    FETCH_COMPANY_PURCHASES_ERROR,
    SAVE_NEW_VOUCHER,
    SAVE_NEW_VOUCHER_SUCCESS,
    SAVE_NEW_VOUCHER_ERROR,
    CONSUME_VOUCHER,
    CONSUME_VOUCHER_SUCCESS, 
    CONSUME_VOUCHER_ERROR,
    CANCEL_VOUCHER,
    CANCEL_VOUCHER_SUCCESS,
    CANCEL_VOUCHER_ERROR,
    SAVE_VOUCHER_INFO,
    SAVE_VOUCHER_INFO_SUCCESS,
    SAVE_VOUCHER_INFO_ERROR
} from '../constants'

export const fetchCompanyPurchases = () => ({
    type: FETCH_COMPANY_PURCHASES
})

export const fetchCompanyPurchasesSuccess = (ids) => ({
    type: FETCH_COMPANY_PURCHASES_SUCCESS,
    payload: ids
})

export const fetchCompanyPurchasesError = (err) => ({
    type: FETCH_COMPANY_PURCHASES_ERROR,
    payload: err
})

export const saveNewVoucher = (data) => ({
    type: SAVE_NEW_VOUCHER,
    payload: data
})

export const saveNewVoucherSuccess = (ids) => ({
    type: SAVE_NEW_VOUCHER_SUCCESS,
    payload: ids
})

export const saveNewVoucherError = (err) => ({
    type: SAVE_NEW_VOUCHER_ERROR,
    payload: err
})

export const consumeVoucher = (code) => ({
    type: CONSUME_VOUCHER,
    payload: { code }
})

export const consumeVoucherSuccess = (data) => ({
    type: CONSUME_VOUCHER_SUCCESS,
    payload: data
})

export const consumeVoucherError = (err) => ({
    type: CONSUME_VOUCHER_ERROR,
    payload: err
})

export const cancelVoucher = (voucher) => ({
    type: CANCEL_VOUCHER,
    payload: voucher
})

export const cancelVoucherSuccess = () => ({
    type: CANCEL_VOUCHER_SUCCESS
})

export const cancelVoucherError = (err) => ({
    type: CANCEL_VOUCHER_ERROR,
    payload: err
})

export const saveVoucherInfo = (voucher) => ({
    type: SAVE_VOUCHER_INFO,
    payload: voucher
})

export const saveVoucherInfoSuccess = () => ({
    type: SAVE_VOUCHER_INFO_SUCCESS,
})

export const saveVoucherInfoError = (err) => ({
    type: SAVE_VOUCHER_INFO_ERROR,
    payload: err
})