export const FETCH_COMPANY_ADVENTURES = 'FETCH_COMPANY_ADVENTURES'
export const FETCH_COMPANY_ADVENTURES_SUCCESS = 'FETCH_COMPANY_ADVENTURES_SUCCESS'
export const FETCH_COMPANY_ADVENTURES_ERROR = 'FETCH_COMPANY_ADVENTURES_ERROR'

export const FETCH_ADVENTURES_CATEGORIES = 'FETCH_ADVENTURES_CATEGORIES'
export const FETCH_ADVENTURES_CATEGORIES_SUCCESS = 'FETCH_ADVENTURES_CATEGORIES_SUCCESS'
export const FETCH_ADVENTURES_CATEGORIES_ERROR = 'FETCH_ADVENTURES_CATEGORIES_ERROR'

export const FETCH_ADVENTURES_TAGS = 'FETCH_ADVENTURES_TAGS'
export const FETCH_ADVENTURES_TAGS_SUCCESS = 'FETCH_ADVENTURES_TAGS_SUCCESS'
export const FETCH_ADVENTURES_TAGS_ERROR = 'FETCH_ADVENTURES_TAGS_ERROR'

export const SAVE_DRAFT_ADVENTURE = 'SAVE_DRAFT_ADVENTURE'
export const SAVE_DRAFT_ADVENTURE_SUCCESS = 'SAVE_DRAFT_ADVENTURE_SUCCESS'
export const SAVE_DRAFT_ADVENTURE_ERROR = 'SAVE_DRAFT_ADVENTURE_ERROR'

export const CREATE_NEW_ADVENTURE = 'CREATE_NEW_ADVENTURE'
export const CREATE_NEW_ADVENTURE_SUCCESS = 'CREATE_NEW_ADVENTURE_SUCCESS'
export const CREATE_NEW_ADVENTURE_ERROR = 'CREATE_NEW_ADVENTURE_ERROR'

export const SELECT_ADVENTURE = 'SELECT_ADVENTURE'
export const SELECT_ADVENTURE_ERROR = 'SELECT_ADVENTURE_ERROR'
export const CLEAR_CURRENT_ADVENTURE = 'CLEAR_CURRENT_ADVENTURE'
export const REFRESH_ADVENTURES = 'REFRESH_ADVENTURES'

export const UPLOAD_IMAGE_FILE_TO_AWS = 'UPLOAD_IMAGE_FILE_TO_AWS'
export const UPLOAD_IMAGE_FILE_TO_AWS_SUCCESS = 'UPLOAD_IMAGE_FILE_TO_AWS_SUCCESS'
export const UPLOAD_IMAGE_FILE_TO_AWS_ERROR = 'UPLOAD_IMAGE_FILE_TO_AWS_ERROR'

export const INIT_ADVENTURE_CREATION = 'INIT_ADVENTURE_CREATION'

export const ADVENTURES_ERRORS = {
    TITLE: {
        EMPTY: 'O campo de título é obrigatório'
    },
    CATEGORY: {
        EMPTY: 'O campo de categoria é obrigatório'
    },
    LONG_DESCRIPTION: {
        EMPTY: 'O campo de descrição é obrigatório'
    },
    STREET: {
        EMPTY: 'O campo de endereço é obrigatório'
    },
    TERMS_ACCEPTANCE: {
        NOT_CHECKED: 'É necessário aceitar os termos para continuar'
    },
    PUBLISH_ACCEPTANCE: {
        NOT_CHECKED: 'É necessário aceitar a publicação para continuar'
    },
    SELL_TYPE: {
        EMPTY: 'É preciso selecionar uma opção',
        NOT_ONLINE_SCHEDULING: 'É necessário utilizar o Offstation para selecionar esta opção',
    },
    PACKAGES: {
        EMPTY: 'É necessário configurar pelo menos um pacote'
    },
    VOUCHER_EXPIRATION_DAYS: {
        EMPTY: 'É necessário configurar a validade dos vouchers'
    },
    VOUCHER_TERMS: {
        EMPTY: 'É necessário descrever os termos de uso do voucher'
    },
    START_DATE: {
        EMPTY: 'É necessário informar uma data de inicio'
    },
    START_HOUR: {
        EMPTY: 'É necessário informar um horário de inicio'
    },
    DURATION: {
        EMPTY: 'É necessário informar a duração da atividade'
    },
}

export const CANCEL_RULES_TYPES = {
    COMPLETE: "Cancelamentos com mais de 30 dias antes da atividade reservada reembolsaremos 90% do valor integral (10% de multa), entre 30 e 21 será de 80%, entre 20 a 15 dias de 50%, 14 a 8 dias de 20% e, por fim, com 7 dias ou menos não ha reembolso. As vagas reservadas e não comparecidas, tanto para atividades de aventura quanto para serviços (alimentação, transporte, seguro, etc), serão cobradas normalmente",
    SIMPLE: "Para o reembolso integral do valor pago, o cancelamento deve ser solicitado até o horário de início registrado no anúncio ou 6 meses após a compra, o que vier antes."
}