import { 
    CREATE_REQUEST_ENTITY, 
    UPDATE_REQUEST_ENTITY,
    DELETE_REQUEST_ENTITY 
} from "./constants";
import { Request } from "../../../models";

export const createRequestEntity = (data) => ({
    type: CREATE_REQUEST_ENTITY,
    payload: new Request(data)
});

export const updateRequestEntity = (data) => ({
    type: UPDATE_REQUEST_ENTITY,
    payload: data
})

export const deleteRequestEntity = (id) => ({
    type: DELETE_REQUEST_ENTITY,
    payload: { id }
})