import { combineReducers } from "redux";
import { CREATE_REQUEST_ENTITY, UPDATE_REQUEST_ENTITY, DELETE_REQUEST_ENTITY } from "./constants";
import { CREATE_RESERVE_ENTITY, DELETE_RESERVE_ENTITY } from "../reserve/constants";
import { addReserveSliceById, removeReserveSliceById } from "./slices";

export const requestById = (state = {}, action) => {
    switch (action.type) {
        case CREATE_REQUEST_ENTITY:
            return { ...state, [action.payload.id]: { ...action.payload } }
        case UPDATE_REQUEST_ENTITY:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload } }
        case DELETE_REQUEST_ENTITY:
            return { ...state, [action.payload.id]: undefined } 
        case CREATE_RESERVE_ENTITY:
            return addReserveSliceById(state, action)
        case DELETE_RESERVE_ENTITY:
            return removeReserveSliceById(state, action)
        default:
            return state;
    }
}

export const requestAllIds = (state = [], action) => {
    switch (action.type) {
        case CREATE_REQUEST_ENTITY:
            return [ ...state, action.payload.id ]
        case DELETE_REQUEST_ENTITY:
            return state.reduce((prevState,curId) => curId === action.payload.id ? prevState : [ ...prevState, curId],[])
        default:
            return state;
    }
}

export default combineReducers({
    byId: requestById,
    allIds: requestAllIds
})