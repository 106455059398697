import { Api } from '../../helpers/api';
const API = new Api()

class ReservationService {
    getActiveSchedules(){
        return API.call('/v2/schedule-rule/get-active-schedule-rules', 'get')
    }

    getActiveRequests(data){
        return API.call('/v2/request/get-active-requests', 'get', data)
    }

    createRequest(data){
        return API.call('/v2/request/create-reservation', 'post', data)
    }

    cancelReserve(pk_reserve){
        return API.call('/v1/payment/cancel-individual-reserve', 'post', { pk_reserve })
    }

    cancelRequest(params){
        return API.call('/v1/request/cancel-request', 'post', { pk_request: params.request.pk_request, description: params.reason })
    }

    changeRequestDate(params){
        return API.call('/v1/request/change-request-date', 'post', { pkRequest: params.request.pk_request, date: params.date })
    }

    changeRequestInfo(params){
        return API.call('/v2/request/update-request-info', 'post', params)
    }

    changeReserveInfo(params){
        return API.call('/v2/request/update-reserve-info', 'post', params)
    }

    sendEmail(params){
        return API.call('/v2/request/send-email', 'post', params)
    }
}

export default new ReservationService()