import { combineReducers } from "redux";
import { 
    CREATE_NEW_SCRIPT,
    DELETE_SCRIPT,
    CREATE_NEW_SCRIPT_ITEM,
    EDIT_SCRIPT_ITEM,
    DELETE_SCRIPT_ITEM
} from "../../constants";

// Script reducers

export function scriptById(state = {}, action) {
    switch (action.type) {
        case CREATE_NEW_SCRIPT:
            return { ...state, [action.payload.id]: { ...action.payload } }
        case DELETE_SCRIPT:
            return { ...state, [action.payload.id]: undefined }
        case CREATE_NEW_SCRIPT_ITEM:
            if (action.payload.script_id && state[action.payload.script_id]) {
                return { ...state, [action.payload.script_id]: { ...state[action.payload.script_id], items: [ ...state[action.payload.script_id].items, action.payload.id ] } }
            } else {
                return state
            }
        case DELETE_SCRIPT_ITEM:
            return Object
                    .keys(state)
                    .reduce((prevState,curID) => ({
                         ...prevState, 
                         [curID]: { 
                            ...state[curID], 
                            items: state[curID].items.reduce((prevItems, curItem) => curItem !== action.payload.id ? [ ...prevItems, curItem] : prevItems, []) 
                        } 
                    }), {})
        default:
            return state;
    }
}

export function scriptAllIds(state = [], action) {
    switch (action.type) {
        case CREATE_NEW_SCRIPT:
            return [ ...state, action.payload.id ]
        case DELETE_SCRIPT:
            return state.reduce((prevState, curId) => curId !== action.payload.id ? [ ...prevState, curId] : prevState, [])
        default:
            return state
    }
}

export default combineReducers({
    byId: scriptById,
    allIds: scriptAllIds
})

// ScriptItem reducers

export function scriptItemById(state = {}, action) {
    switch (action.type) {
        case CREATE_NEW_SCRIPT_ITEM:
            return { ...state, [action.payload.id]: { ...action.payload } }
        case EDIT_SCRIPT_ITEM:
            return { ...state, [action.payload.id]: { ...action.payload } }
        case DELETE_SCRIPT_ITEM:
            return { ...state, [action.payload.id]: undefined }
        default:
            return state;
    }
}

export function scriptItemAllIds(state = [], action) {
    switch (action.type) {
        case CREATE_NEW_SCRIPT_ITEM:
            return [ ...state, action.payload.id ]
        case DELETE_SCRIPT_ITEM:
            return state.reduce((prevState, curId) => curId !== action.payload.id ? [ ...prevState, curId] : prevState, [])
        default:
            return state;
    }
}

export const scriptItemReducer = combineReducers({
    byId: scriptItemById,
    allIds: scriptItemAllIds
})