
import { put, all, call, select } from 'redux-saga/effects'
import {
    createRequestEntity,
    createReserveEntity,
    createScheduleRule
} from '../actions'
import { Request, Reserve, Payment, User, Package } from '../../models'
import { normalizeAdventureOnSaga } from '../adventures/utils'
import { createPayment } from '../entities/payment/actions'
import { createPackage } from '../entities/package/actions'
import { createUser } from '../entities/user/actions'
const selectEntities = (state) => state.entities

/**
 * Função para normalizar um objeto request criando suas entities atreladas
 * @param {Object} request Objeto não normalizado
 * @return {Object} Objeto normalizado
 */
export function* normalizeRequestOnSaga(request){
    const entities = yield select(selectEntities)
    const request_m = new Request(request)

    // Se tiver uma aventura, normaliza a aventura
    if (request.adventure) {
        // Busca a aventura já criada na store
        let adv_m = yield call(() => Object.values(entities.adventure.byId).find(adv => adv.pk_adventure === request.adventure.pk_adventure))

        if(!adv_m){
            // Normaliza a aventura
            adv_m = yield call(normalizeAdventureOnSaga, request.adventure)
        }

        request_m.adventure_id = adv_m.id
    } else {
         // Busca a aventura já criada na store pelA FK
         let adv_m = yield call(() => Object.values(entities.adventure.byId).find(adv => adv.pk_adventure === request.fk_adventure))

         if (adv_m){
            request_m.adventure_id = adv_m.id
         }
    }

    // Se tiver reservas dentro do agendamento, normaliza elas
    if (request.reserves) {
        const reserves_id = []
        
        // Para cada reserve normaliza ela
        yield all(request.reserves.map(function*(reserve){
            const reserve_m = yield call(normalizeReserveOnSaga, { ...reserve, request_id: request_m.id, adventure_id: request_m.adventure_id } )
            reserves_id.push(reserve_m.id)
        }))
        request_m.reserves = reserves_id
    }

    // Cria a entity se ja nao estiver criada final na store
    const created_request_id = entities.request.allIds.find(id => entities.request.byId[id].pk_request === request_m.pk_request)
    if(created_request_id) request_m.id = created_request_id
    
    yield put(createRequestEntity(request_m))
    return request_m
}

/**
 * Função para normalizar um objeto schedule_rule criando suas entities atreladas
 * @param {Object} rule Objeto não normalizado
 * @return {Object} Objeto normalizado
 */
export function* normalizeScheduleRuleOnSaga(rule) {
    const entities = yield select(selectEntities)
    for (const id in entities.adventure.byId) {
        if (entities.adventure.byId.hasOwnProperty(id)) {
            if (entities.adventure.byId[id].pk_adventure === rule.fk_adventure) {
                rule.adventure_id = id
                break;
            }
        }
    }
    
    const createRuleAction = yield put(createScheduleRule(rule))
    const rule_m = createRuleAction.payload
    return rule_m
}


/**
 * Função para normalizar um objeto reserve criando suas entities atreladas
 * @param {Object} reserve Objeto não normalizado
 * @return {Object} Objeto normalizado
 */
export function* normalizeReserveOnSaga(reserve) {
    const entities = yield select(selectEntities)
    const reserve_m = new Reserve(reserve)

    // Se tiver uma aventura, normaliza a aventura
    // if (!!reserve.adventure) {
    //     // Busca a aventura já criada na store
    //     let adv_m = Object.values(entities.adventure.byId).find(adv => adv.pk_adventure === reserve.adventure.pk_adventure)

    //     if (!adv_m) {
    //         // Normaliza a aventura
    //         adv_m = yield call(normalizeAdventureOnSaga, reserve.adventure)
    //     }

    //     reserve_m.adventure_id = adv_m.id
    // }

    // Se tiver um pagamento, normaliza o pagamento
    if (!!reserve.payment) {
        // Busca o pagamento ja criado
        let pay_m = yield call(() => Object.values(entities.payment.byId).find(pay => pay.pk_payment === reserve.payment.pk_payment))

        if (!pay_m) {
            // Normaliza pagamento
            pay_m = yield call(normalizePaymentOnSaga, reserve.payment)
        }

        reserve_m.payment_id = pay_m.id
    }
    
    // Se tiver um pagamento, normaliza o pagamento
    if (!!reserve.package) {
        // Busca o pacote ja criado
        let pack_m = yield call(() => Object.values(entities.package.byId).find(pack => pack.pk_package === reserve.package.pk_package))

        if (!pack_m) {
            // Normaliza pagamento
            pack_m = yield call(normalizePackageOnSaga, reserve.package)
        }

        reserve_m.package_id = pack_m.id
    } else if (!!reserve.requestPackage) {
        // Normaliza pagamento
        const pack_m = yield call(normalizePackageOnSaga, reserve.requestPackage)

        reserve_m.package_id = pack_m.id
    }

    // Se tiver um pagamento, normaliza o pagamento
    if (!!reserve.user) {
        // Busca o user ja criado
        let user_m = yield call(() => Object.values(entities.user.byId).find(user => user.pk_user === reserve.user.pk_user))

        if (!user_m) {
            // Normaliza user
            user_m = yield call(normalizeUserOnSaga, reserve.user)
        }

        reserve_m.user_id = user_m.id
    }

    yield put(createReserveEntity(reserve_m))
    return reserve_m
}

/**
 * Função para normalizar um objeto payment criando suas entities atreladas
 * @param {Object} payment Objeto não normalizado
 * @return {Object} Objeto normalizado
 */
export function* normalizePaymentOnSaga(payment) {
    const payment_m = new Payment(payment)
    yield put(createPayment(payment_m))
    return payment_m
}

/**
 * Função para normalizar um objeto package criando suas entities atreladas
 * @param {Object} pack Objeto não normalizado
 * @return {Object} Objeto normalizado
 */
export function* normalizePackageOnSaga(pack) {
    const package_m = new Package(pack)
    yield put(createPackage(package_m))
    return package_m
}

/**
 * Função para normalizar um objeto user criando suas entities atreladas
 * @param {Object} user Objeto não normalizado
 * @return {Object} Objeto normalizado
 */
export function* normalizeUserOnSaga(user) {
    const user_m = new User(user)
    yield put(createUser(user_m))
    return user_m
}