/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';
export const CLEAR_FORGET_PASSWORD = 'CLEAR_FORGOT_PASSWORD';

export const UPDATE_USER_FIELDS = 'UPDATE_USER_FIELDS';

export const SAVE_BANK_ATTRIBUTES = 'SAVE_BANK_ATTRIBUTES';
export const SAVE_BANK_ATTRIBUTES_SUCCESS = 'SAVE_BANK_ATTRIBUTES_SUCCESS';
export const SAVE_BANK_ATTRIBUTES_ERROR = 'SAVE_BANK_ATTRIBUTES_ERROR';

export const SAVE_BUSINESS_ATTRIBUTES = 'SAVE_BUSINESS_ATTRIBUTES';
export const SAVE_BUSINESS_ATTRIBUTES_SUCCESS = 'SAVE_BUSINESS_ATTRIBUTES_SUCCESS';
export const SAVE_BUSINESS_ATTRIBUTES_ERROR = 'SAVE_BUSINESS_ATTRIBUTES_ERROR';

export const SEND_DOCUMENTS = 'SEND_DOCUMENTS';
export const SEND_DOCUMENTS_SUCCESS = 'SEND_DOCUMENTS_SUCCESS';
export const SEND_DOCUMENTS_ERROR = 'SEND_DOCUMENTS_ERROR';

export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';