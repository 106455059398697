/**
 * Requests: Agendamentos de horário
 */
export const FETCH_REQUESTS_FOR_MONTH = 'FETCH_REQUESTS_FOR_MONTH'
export const FETCH_REQUESTS_FOR_MONTH_SUCCESS = 'FETCH_REQUESTS_FOR_MONTH_SUCCESS'
export const FETCH_REQUESTS_FOR_MONTH_ERROR = 'FETCH_REQUESTS_FOR_MONTH_ERROR'

export const FETCH_REQUESTS_FOR_DAY = 'FETCH_REQUESTS_FOR_DAY'
export const FETCH_REQUESTS_FOR_DAY_SUCCESS = 'FETCH_REQUESTS_FOR_DAY_SUCCESS'
export const FETCH_REQUESTS_FOR_DAY_ERROR = 'FETCH_REQUESTS_FOR_DAY_ERROR'

export const RELOAD_REQUESTS = 'RELOAD_REQUESTS'

export const FETCH_REQUEST_BY_PK = 'FETCH_REQUEST_BY_PK'
export const FETCH_REQUEST_BY_PK_SUCCESS = 'FETCH_REQUEST_BY_PK_SUCCESS'
export const FETCH_REQUEST_BY_PK_ERROR = 'FETCH_REQUEST_BY_PK_ERROR'

export const CREATE_REQUEST = 'CREATE_REQUEST'
export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS'
export const CREATE_REQUEST_ERROR = 'CREATE_REQUEST_ERROR'

export const CANCEL_REQUEST = 'CANCEL_REQUEST'
export const CANCEL_REQUEST_SUCCESS = 'CANCEL_REQUEST_SUCCESS'
export const CANCEL_REQUEST_ERROR = 'CANCEL_REQUEST_ERROR'

export const CHANGE_REQUEST_DATE = 'CHANGE_REQUEST_DATE'
export const CHANGE_REQUEST_DATE_SUCCESS = 'CHANGE_REQUEST_DATE_SUCCESS'
export const CHANGE_REQUEST_DATE_ERROR = 'CHANGE_REQUEST_DATE_ERROR'

export const CHANGE_REQUEST_INFO = 'CHANGE_REQUEST_INFO'
export const CHANGE_REQUEST_INFO_SUCCESS = 'CHANGE_REQUEST_INFO_SUCCESS'
export const CHANGE_REQUEST_INFO_ERROR = 'CHANGE_REQUEST_INFO_ERROR'

/**
 * Reserves: Participantes em horários
 */
export const CANCEL_RESERVE = 'CANCEL_RESERVE'
export const CANCEL_RESERVE_SUCCESS = 'CANCEL_RESERVE_SUCCESS'
export const CANCEL_RESERVE_ERROR = 'CANCEL_RESERVE_ERROR'

export const CHANGE_RESERVE_INFO = 'CHANGE_RESERVE_INFO'
export const CHANGE_RESERVE_INFO_SUCCESS = 'CHANGE_RESERVE_INFO_SUCCESS'
export const CHANGE_RESERVE_INFO_ERROR = 'CHANGE_RESERVE_INFO_ERROR'

/**
 * ScheduleRules: Regras de disponibilidade de horários e valores para horários
 */
export const FETCH_SCHEDULE = 'FETCH_SCHEDULE'
export const FETCH_SCHEDULE_SUCCESS = 'FETCH_SCHEDULE_SUCCESS'
export const FETCH_SCHEDULE_ERROR = 'FETCH_SCHEDULE_ERROR'

/**
 * Modo de exibição do calendario
 */
export const CHANGE_VIEW_MODE = 'CHANGE_VIEW_MODE'
export const CHANGE_CURRENT_DATE = 'CHANGE_CURRENT_DATE'
export const VIEW_MODE_MONTH = 'VIEW_MODE_MONTH'
export const VIEW_MODE_WEEK = 'VIEW_MODE_WEEK'
export const VIEW_MODE_DAY = 'VIEW_MODE_DAY'
export const VIEW_MODE_YEAR = 'VIEW_MODE_YEAR'

export const SELECT_REQUEST = 'SELECT_REQUEST'
export const LOAD_CALENDAR = 'LOAD_CALENDAR'
export const CALENDAR_LODADED = 'CALENDAR_LODADED'

export const SEND_EMAIL = 'SEND_EMAIL'
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS'
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR'