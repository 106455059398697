import { combineReducers } from "redux";
import {
    CREATE_REQUEST_CUSTOM_FIELD,
    UPDATE_REQUEST_CUSTOM_FIELD,
    DELETE_REQUEST_CUSTOM_FIELD
} from '../../constants'

export function requestCustomFieldById(state = {}, action){
    switch (action.type) {
        case CREATE_REQUEST_CUSTOM_FIELD:
            return { ...state, [action.payload.id]: action.payload }
        case UPDATE_REQUEST_CUSTOM_FIELD:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload } }
        case DELETE_REQUEST_CUSTOM_FIELD:
            return { ...state, [action.payload.id]: undefined }
        default:
            return state
    }
}

export function requestCustomFieldAllIds(state = [], action){
    switch (action.type) {
        case CREATE_REQUEST_CUSTOM_FIELD:
            return [ ...state, action.payload.id ]
        case DELETE_REQUEST_CUSTOM_FIELD:
            return state.reduce((prevState, curId) => curId !== action.payload.id ? [ ...prevState, curId ] : prevState, [])
        default:
            return state
    }
}

export default combineReducers({
    byId: requestCustomFieldById,
    allIds: requestCustomFieldAllIds
})