import VouchersService from './service'
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
    FETCH_COMPANY_PURCHASES,
    SAVE_NEW_VOUCHER,
    CONSUME_VOUCHER,
    CANCEL_VOUCHER,
    SAVE_VOUCHER_INFO
} from '../constants'
import {
    fetchCompanyPurchasesSuccess,
    fetchCompanyPurchasesError,
    saveNewVoucherSuccess,
    saveNewVoucherError,
    consumeVoucherError,
    consumeVoucherSuccess,
    updateVoucher,
    cancelVoucherSuccess,
    cancelVoucherError,
    saveVoucherInfoSuccess,
    saveVoucherInfoError
} from '../actions'
import { normalizeVoucherOnSaga } from './utils'
import { normalizeVoucher } from '../../models';

export function* getCompanyPurchases() {
    const res = yield call(VouchersService.getCompanyPurchases)
    // Criar vouchers
    if (res.status === 200) {
        const voucher_ids = []
        yield all(res.data.map(function*(voucher){
            const voucher_m = yield call(normalizeVoucherOnSaga,voucher)
            voucher_ids.push(voucher_m.id)
        }))
        yield put(fetchCompanyPurchasesSuccess(voucher_ids))
    } else {
        yield put(fetchCompanyPurchasesError(res))
    }
}

export function* createNewVoucher({ payload }) {
    const res = yield call(VouchersService.saveVoucher, payload)
    if (res.status === 200) {
        const voucher_ids = []
        yield all(res.data.map(function*(voucher){
            const voucher_m = yield call(normalizeVoucherOnSaga,voucher)
            voucher_ids.push(voucher_m.id)
        }))
        yield put(saveNewVoucherSuccess(voucher_ids))
    } else {
        yield put(saveNewVoucherError(res))
    }
}

export function* consumeVoucher({ payload }) {
    const state = yield select()
    const { code } = payload
    const voucher = Object.values(state.entities.voucher.byId).find(voucher => voucher.code === code)
    if (!voucher) {
        return yield put(consumeVoucherError({ message: "Voucher não encontrado. Verifique o código" }))
    }

    const res = yield call(VouchersService.consumeVoucher, voucher)
    if (res.status === 200) {
        yield put(updateVoucher(normalizeVoucher({ ...voucher, ...res.data })))
        yield put(consumeVoucherSuccess())
    } else {
        yield put(consumeVoucherError(res))
    }
}

export function* cancelVoucher({ payload }) {
    const voucher = payload
    const res = yield call(VouchersService.cancelVoucher, voucher)
    if (res.status === 200) {
        yield put(updateVoucher(normalizeVoucher({ ...voucher, ...res.data })))
        yield put(cancelVoucherSuccess())
    } else {
        yield put(cancelVoucherError(res))
    }
}

export function* saveVoucherInfo({ payload }) {
    const voucher = payload
    const res = yield call(VouchersService.saveVoucherInfo, voucher)
    if (res.status === 200) {
        yield put(updateVoucher(normalizeVoucher({ ...voucher, ...res.data })))
        yield put(saveVoucherInfoSuccess())
    } else {
        yield put(saveVoucherInfoError(res))
    }
}

export function* watchFetchCompanyPurchases() {
    yield takeEvery(FETCH_COMPANY_PURCHASES, getCompanyPurchases)
}

export function* watchSaveNewVoucher() {
    yield takeEvery(SAVE_NEW_VOUCHER, createNewVoucher)
}

export function* watchConsumeVoucher() {
    yield takeEvery(CONSUME_VOUCHER, consumeVoucher)
}

export function* watchCancelVoucher() {
    yield takeEvery(CANCEL_VOUCHER, cancelVoucher)
}

export function* watchSaveVoucherInfo() {
    yield takeEvery(SAVE_VOUCHER_INFO, saveVoucherInfo)
}

function* vouchersSaga(){
    yield all([
        fork(watchFetchCompanyPurchases), 
        fork(watchSaveNewVoucher),
        fork(watchConsumeVoucher),
        fork(watchCancelVoucher),
        fork(watchSaveVoucherInfo)
    ])
}

export default vouchersSaga