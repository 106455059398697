import { Api } from '../../helpers/api';
const API = new Api();

class AuthService {
    login(data){
        return API.call('/v1/company/login', 'post', data)
    }

    forgotPassword(email){
        return API.call('/v1/company/forgot-password', 'post', { email })
    }

    registerUser(data){
        return API.call('/v2/company/create-company-account', 'post', data)
    }

    updateUser(data) {
        return API.call('/v2/company/update-company-account', 'post', data);
    }

    updateBusinessAttributes(data){
        return API.call('/v1/company/update-business-attributes', 'post', data)
    }

    updateBankAttributes(data){
        return API.call('/v1/company/update-bank-attributes', 'post', data)
    }

    sendDocument(files){
        const payload = new FormData()
        files.forEach(file => payload.append(file.juno_type, file))
        return API.call('/v2/juno/send-files', 'post', payload)
    }

}

export default new AuthService()