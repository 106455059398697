import { Api } from '../../helpers/api';
const API = new Api();

class FinanceService {
    getFinancesBalances(){
        // return API.call('/v1/transference/moip-balance', 'get');
        return API.call('/v2/juno/get-balance', 'post');
    }

    getExtractOfMonth(params){
        return API.call('/v1/payment/get-finances-extract', 'get', params);
    }

    transferAvailableMoipBalance(params){
        return API.call('/v2/transference/transfer-available-moip-balance', 'post', params);
    }
}

export default new FinanceService()