import { combineReducers } from "redux";
import { 
    CREATE_ADVENTURE,
    UPDATE_ADVENTURE,
    CREATE_NEW_SCRIPT,
    CREATE_NEW_FAQ,
    CREATE_PACKAGE,
    CREATE_SCHEDULE_RULE,
    CREATE_IMAGE,
    CREATE_REQUEST_CUSTOM_FIELD,
    CREATE_RESERVE_CUSTOM_FIELD,
    DELETE_SCRIPT,
    DELETE_FAQ,
    DELETE_PACKAGE,
    DELETE_IMAGE,
    DELETE_REQUEST_CUSTOM_FIELD,
    DELETE_RESERVE_CUSTOM_FIELD,
    DELETE_SCHEDULE_RULE
}  from '../../constants'
import { 
    addScriptSliceById, 
    addFAQSliceById, 
    addPackageSliceById, 
    addScheduleRuleSliceById, 
    addImageSliceById, 
    addRequestCustomFieldSliceById, 
    addReserveCustomFieldSliceById, 
    removeScriptSliceById,
    removeFAQSliceById,
    removePackageSliceById,
    removeScheduleRuleSliceById,
    removeImageSliceById,
    removeRequestCustomFieldSliceById,
    removeReserveCustomFieldSliceById
} from './slices'
import {  } from "../FAQ/constants";

export function adventureById(state = {}, action) {
    switch (action.type) {
        case CREATE_ADVENTURE:
            return { ...state, [action.payload.id]: { ...action.payload } }
        case UPDATE_ADVENTURE:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload }}
        case CREATE_NEW_SCRIPT:
            return addScriptSliceById(state, action)
        case DELETE_SCRIPT:
            return removeScriptSliceById(state, action)
        case CREATE_NEW_FAQ:
            return addFAQSliceById(state, action)
        case DELETE_FAQ:
            return removeFAQSliceById(state, action)
        case CREATE_PACKAGE:
            return addPackageSliceById(state, action)
        case DELETE_PACKAGE:
            return removePackageSliceById(state, action)
        case CREATE_SCHEDULE_RULE:
            return addScheduleRuleSliceById(state, action)
        case DELETE_SCHEDULE_RULE:
            return removeScheduleRuleSliceById(state, action)
        case CREATE_IMAGE:
            return addImageSliceById(state, action)
        case DELETE_IMAGE:
            return removeImageSliceById(state, action)
        case CREATE_REQUEST_CUSTOM_FIELD:
            return addRequestCustomFieldSliceById(state, action)
        case DELETE_REQUEST_CUSTOM_FIELD:
            return removeRequestCustomFieldSliceById(state, action)
        case CREATE_RESERVE_CUSTOM_FIELD:
            return addReserveCustomFieldSliceById(state, action)
        case DELETE_RESERVE_CUSTOM_FIELD:
            return removeReserveCustomFieldSliceById(state, action)
        default:
            return state
    }
}

export function adventureAllIds(state = [], action) {
    switch (action.type) {
        case CREATE_ADVENTURE:
            return  [ ...state, action.payload.id ]
        default:
            return state
    }
}

export default combineReducers({
    byId: adventureById,
    allIds: adventureAllIds
})