import ReservationService from './service'
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

import {
    FETCH_SCHEDULE, 
    FETCH_REQUESTS_FOR_DAY, 
    CREATE_REQUEST, 
    CANCEL_REQUEST, 
    CANCEL_RESERVE, 
    SELECT_REQUEST,
    CHANGE_REQUEST_DATE,
    LOAD_CALENDAR,
    RELOAD_REQUESTS,
    FETCH_REQUESTS_FOR_MONTH,
    CHANGE_REQUEST_INFO,
    CHANGE_RESERVE_INFO,
    SEND_EMAIL
} from './constants'

import {
    fetchRequestsForDaySuccess,
    fetchRequestsForDayError,
    fetchScheduleSuccess,
    fetchScheduleError,
    createRequestSuccess,
    createRequestError,
    cancelRequestSuccess,
    cancelRequestError,
    cancelReserveSuccess,
    cancelReserveError,
    changeRequestDateSuccess,
    changeRequestDateError,
    selectRequest,
    calendarLoaded,
    fetchRequestsForDay,
    fetchRequestsForMonthError,
    fetchRequestsForMonthSuccess,
    fetchRequestsForMonth,
    changeRequestInfoSuccess,
    changeRequestInfoError,
    changeReserveInfoSuccess,
    changeReserveInfoError,
    sendEmailSuccess,
    sendEmailError
} from './actions'
import moment from 'moment'
import { history } from '../../routes';
import { normalizeRequestOnSaga, normalizeScheduleRuleOnSaga, normalizeReserveOnSaga } from './utils';
import { deleteReserveEntity, updateReserveEntity } from '../entities/reserve/actions';
import { getCompanyAdventures } from '../adventures/saga';
import { updateRequestEntity, deleteRequestEntity } from '../entities/request/actions';

const selectAllRequests = (state) => state.entities.request.allIds.map(id => state.entities.request.byId[id])

/**
 * Função para consultar os próximos agendamentos da Empresa
 * A identificação da empresa é feita através do usuário logado
 */
export function* getRequests({ payload }){
    const { date } = payload
    const res = yield call(ReservationService.getActiveRequests, { date, viewType: 'day' })

    if (res.status === 200) {
        const requests_ids = []

        yield all(res.data.map(function*(json){
            const request_m = yield call(normalizeRequestOnSaga, json)
            requests_ids.push(request_m.id)
        }))

        yield put(fetchRequestsForDaySuccess(requests_ids))
    } else {
        yield put(fetchRequestsForDayError(res))
    }
}

export function* getMonthRequestsPreview({ payload }){
    const { date, preview } = payload
    const res = yield call(ReservationService.getActiveRequests, { date, preview, viewType: 'month' })

    if (res.status === 200) {
        yield put(fetchRequestsForMonthSuccess(res.data))
    } else {
        yield put(fetchRequestsForMonthError(res))
    }
}

/**
 * Consulta calendario de uma aventura para o mês
 */
export function* getSchedule(){
    const res = yield call(ReservationService.getActiveSchedules)

    if (res.status === 200) {
        const rules_ids = []

        yield all(res.data.map(function*(obj){
            const rule_m = yield call(normalizeScheduleRuleOnSaga, obj)
            rules_ids.push(rule_m.id)
        }));

        yield put(fetchScheduleSuccess(rules_ids))
    } else {
        yield put(fetchScheduleError(res))
    }
}

/**
 * Saga para criar agendamentos e ingressos dentro do agendamento
 * @param {Object} action 
 */
export function* createRequestSaga({ payload }) {
    // Pega todos os agendamentos na store
    const requests = yield select(selectAllRequests)
    // Cria a nova reserva
    const res = yield call(ReservationService.createRequest, payload)
    if (res.status === 200) {
        // Verifica se o agendamento ja existe
        // se ja existe, cria as reservas. Do contrario cria o agendamento inteiro
        const createdRequest = requests.find(req => req.pk_request === res.data.pk_request)
        if (createdRequest) {
            const reserves_ids = []
            
            yield all(res.data.reserves.map(function*(reserve){
                const reserve_m = yield call(normalizeReserveOnSaga, { ...reserve, request_id: createdRequest.id })
                reserves_ids.push(reserve_m.id)
            }))

            yield put(updateRequestEntity({ ...createdRequest, reserves: reserves_ids }))
            yield put(createRequestSuccess(createdRequest.id))
        } else {
            const request_m = yield call(normalizeRequestOnSaga,res.data)
            yield put(createRequestSuccess(request_m.id))
            yield put(selectRequest(request_m.id))
        }
    } else {
        yield put(createRequestError(res))
    }
}

export function* cancelReserveSaga({ payload }) {
    const res = yield call(ReservationService.cancelReserve, payload.pk_reserve)
    if (res.status === 200) {
        yield put(deleteReserveEntity(payload.id))
        yield put(cancelReserveSuccess())
    } else {
        yield put(cancelReserveError(res))
    }
}

export function* selectRequestSaga({ payload: { id } }) {
    const store = yield select()
    if (!store.entities.request.byId[id]) {
        yield put(selectRequest(null))
        yield call(history.push, '/schedule')
    } else {
        yield call(history.push, `/schedule/events/${id}`)
    }
}

export function* cancelRequest({ payload }) {
    const res = yield call(ReservationService.cancelRequest, payload);
    if(res.status === 200) {
        yield call(history.push, '/schedule/events')
        yield put(deleteRequestEntity(payload.request.id))
        yield put(cancelRequestSuccess());
    }
    else {
        yield put(cancelRequestError(res));
    }
}

export function* changeRequestDate({ payload }) {
    const res = yield call(ReservationService.changeRequestDate, payload);
    if (res.status === 200) {
        yield put(updateRequestEntity({ ...payload.request, date: payload.date }))
        yield put(changeRequestDateSuccess());
    } else {
        yield put(changeRequestDateError(res));
    }
}

export function* changeRequestInfoSaga({ payload }) {
    const res = yield call(ReservationService.changeRequestInfo, payload)
    if (res.status === 200) {
        yield put(updateRequestEntity({ ...payload }))
        yield put(changeRequestInfoSuccess())
    } else {
        yield put(changeRequestInfoError(res))
    }
}

export function* changeReserveInfoSaga({ payload }) {
    const res = yield call(ReservationService.changeReserveInfo, payload)
    if (res.status === 200) {
        yield put(updateReserveEntity({ ...payload }))
        yield put(changeReserveInfoSuccess())
    } else {
        yield put(changeReserveInfoError(res))
    }
}

export function* loadCalendar() {
    const state = yield select()
    if(!state.Adventures.adventures.length)
        yield call(getCompanyAdventures)
    
    if(!state.Reservation.requests.length)
        yield put(fetchRequestsForDay(moment().format('YYYY-MM-DD')))

    if(!state.Reservation.requestsPreview.length)
        yield put(fetchRequestsForMonth(moment().format('YYYY-MM-DD')))

    if(!state.Reservation.schedule.length)
        yield call(getSchedule)

    yield put(calendarLoaded())
}

export function* reloadRequests() {
    const state = yield select()
    const currentDate = state.Reservation.currentDate
    yield put(fetchRequestsForDay(moment(currentDate).format('YYYY-MM-DD')))
    yield put(fetchRequestsForMonth(moment(currentDate).format('YYYY-MM-DD')))
}

export function* sendEmailSaga({ payload }) {
    const res = yield call(ReservationService.sendEmail, payload)

    if (res.status === 200) {
        yield put(sendEmailSuccess(payload.emailType))
    } else {
        yield put(sendEmailError(res, payload.emailType))
    }
}

export function* watchFetchSchedule() {
    yield takeEvery(FETCH_SCHEDULE, getSchedule)
}

export function* watchFetchRequests() {
    yield takeEvery(FETCH_REQUESTS_FOR_DAY, getRequests)
}

export function* watchFetchRequestsForMonthView() {
    yield takeEvery(FETCH_REQUESTS_FOR_MONTH, getMonthRequestsPreview)
}

export function* watchCreateRequest() {
    yield takeEvery(CREATE_REQUEST, createRequestSaga)
}

export function* watchCancelReserve() {
    yield takeEvery(CANCEL_RESERVE, cancelReserveSaga)
}

export function* watchSelectRequest() {
    yield takeEvery(SELECT_REQUEST, selectRequestSaga)
}

export function* watchCancelRequest() {
    yield takeEvery(CANCEL_REQUEST, cancelRequest);
}

export function* watchChangeRequestDate() {
    yield takeEvery(CHANGE_REQUEST_DATE, changeRequestDate)
}

export function* watchChangeRequestInfo() {
    yield takeEvery(CHANGE_REQUEST_INFO, changeRequestInfoSaga)
}

export function* watchChangeReserveInfo() {
    yield takeEvery(CHANGE_RESERVE_INFO, changeReserveInfoSaga)
}

export function* watchLoadCalendar() {
    yield takeEvery(LOAD_CALENDAR, loadCalendar)
}

export function* watchReloadRequests() {
    yield takeEvery(RELOAD_REQUESTS, reloadRequests)
}

export function* watchSendEmail() {
    yield takeEvery(SEND_EMAIL, sendEmailSaga)
}

function* reservationSaga(){
    yield all([
        fork(watchFetchSchedule), 
        fork(watchFetchRequests),
        fork(watchFetchRequestsForMonthView),
        fork(watchCreateRequest),
        fork(watchCancelRequest),
        fork(watchCancelReserve),
        fork(watchChangeRequestDate),
        fork(watchChangeRequestInfo),
        fork(watchChangeReserveInfo),
        fork(watchSelectRequest),
        fork(watchChangeRequestDate),
        fork(watchLoadCalendar),
        fork(watchReloadRequests),
        fork(watchSendEmail)
    ])
}

export default reservationSaga