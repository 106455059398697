import { combineReducers } from "redux";
import {
    CREATE_TAG
} from '../../constants'

export function tagById(state = {}, action) {
    switch (action.type) {
        case CREATE_TAG:
            return { ...state, [action.payload.id]: action.payload }

        default:
            return state
    }
}

export function tagAllIds(state = [], action) {
    switch (action.type) {
        case CREATE_TAG:
            return [ ...state, action.payload.id ]
    
        default:
            return state
    }
}


export default combineReducers({
    byId: tagById,
    allIds: tagAllIds
})