import {
    FETCH_REQUEST_BY_PK,
    FETCH_REQUEST_BY_PK_ERROR,
    FETCH_REQUEST_BY_PK_SUCCESS,
    CHANGE_VIEW_MODE,
    CHANGE_CURRENT_DATE,
    FETCH_REQUESTS_FOR_MONTH,
    FETCH_REQUESTS_FOR_MONTH_SUCCESS,
    FETCH_REQUESTS_FOR_MONTH_ERROR,
    FETCH_REQUESTS_FOR_DAY,
    FETCH_REQUESTS_FOR_DAY_SUCCESS,
    FETCH_REQUESTS_FOR_DAY_ERROR,
    FETCH_SCHEDULE,
    FETCH_SCHEDULE_SUCCESS,
    FETCH_SCHEDULE_ERROR,
    CREATE_REQUEST,
    CREATE_REQUEST_SUCCESS,
    CREATE_REQUEST_ERROR,
    CANCEL_REQUEST,
    CANCEL_REQUEST_SUCCESS,
    CANCEL_REQUEST_ERROR,
    CANCEL_RESERVE,
    CANCEL_RESERVE_SUCCESS,
    CANCEL_RESERVE_ERROR,
    CHANGE_REQUEST_DATE,
    CHANGE_REQUEST_DATE_SUCCESS,
    CHANGE_REQUEST_DATE_ERROR,
    SELECT_REQUEST,
    LOAD_CALENDAR
} from '../constants'
import { CALENDAR_LODADED, RELOAD_REQUESTS, CHANGE_REQUEST_INFO, CHANGE_REQUEST_INFO_SUCCESS, CHANGE_REQUEST_INFO_ERROR, CHANGE_RESERVE_INFO, CHANGE_RESERVE_INFO_SUCCESS, CHANGE_RESERVE_INFO_ERROR, SEND_EMAIL, SEND_EMAIL_ERROR, SEND_EMAIL_SUCCESS } from './constants'

export const fetchRequestsForMonth = (date) => ({
    type: FETCH_REQUESTS_FOR_MONTH,
    payload: { date, preview: true }
})

export const fetchRequestsForMonthSuccess = (ids) => ({
    type: FETCH_REQUESTS_FOR_MONTH_SUCCESS,
    payload: ids
})

export const fetchRequestsForMonthError = (error) => ({
    type: FETCH_REQUESTS_FOR_MONTH_ERROR,
    payload: error
})

export const fetchRequestsForDay = (date) => ({
    type: FETCH_REQUESTS_FOR_DAY,
    payload: { date }
})

export const fetchRequestsForDaySuccess = (ids) => ({
    type: FETCH_REQUESTS_FOR_DAY_SUCCESS,
    payload: ids
})

export const fetchRequestsForDayError = (error) => ({
    type: FETCH_REQUESTS_FOR_DAY_ERROR,
    payload: error
})

export const fetchRequestByPk = (pk_request) => ({
    type: FETCH_REQUEST_BY_PK,
    payload: { pk_request }
})

export const fetchRequestByPkSuccess = (data) => ({
    type: FETCH_REQUEST_BY_PK_SUCCESS,
    payload: data
})

export const fetchRequestByPkError = (error) => ({
    type: FETCH_REQUEST_BY_PK_ERROR,
    payload: error
})

export const fetchSchedule = () => ({
    type: FETCH_SCHEDULE
})

export const fetchScheduleSuccess = (data) => ({
    type: FETCH_SCHEDULE_SUCCESS,
    payload: data
})

export const fetchScheduleError = (error) => ({
    type: FETCH_SCHEDULE_ERROR,
    payload: error
})

export const changeViewMode = (mode) => ({
    type: CHANGE_VIEW_MODE,
    payload: { mode }
})

export const changeCurrentDate = (date) => ({
    type: CHANGE_CURRENT_DATE,
    payload: { date }
})

export const createRequest = (data) => ({
    type: CREATE_REQUEST,
    payload: data
})

export const createRequestSuccess = (id) => ({
    type: CREATE_REQUEST_SUCCESS,
    payload: { id }
})

export const createRequestError = (err) => ({
    type: CREATE_REQUEST_ERROR,
    payload: err
})

export const cancelRequest = (request, reason) => ({
    type: CANCEL_REQUEST,
    payload: { request, reason }
})

export const cancelRequestSuccess = () => ({
    type: CANCEL_REQUEST_SUCCESS
})

export const cancelRequestError = (err) => ({
    type: CANCEL_REQUEST_ERROR,
    payload: err
})

export const changeReserveInfo = (data) => ({
    type: CHANGE_RESERVE_INFO,
    payload: data
})

export const changeReserveInfoSuccess = () => ({
    type: CHANGE_RESERVE_INFO_SUCCESS,
})

export const changeReserveInfoError = (err) => ({
    type: CHANGE_RESERVE_INFO_ERROR,
    payload: err
})

export const cancelReserve = (reserve) => ({
    type: CANCEL_RESERVE,
    payload: reserve
})

export const cancelReserveSuccess = () => ({
    type: CANCEL_RESERVE_SUCCESS
})

export const cancelReserveError = (err) => ({
    type: CANCEL_RESERVE_ERROR,
    payload: err
})

export const changeRequestInfo = (data) => ({
    type: CHANGE_REQUEST_INFO,
    payload: data
})

export const changeRequestInfoSuccess = () => ({
    type: CHANGE_REQUEST_INFO_SUCCESS
})

export const changeRequestInfoError = (err) => ({
    type: CHANGE_REQUEST_INFO_ERROR,
    payload: err
})

export const changeRequestDate = (request, date) => ({
    type: CHANGE_REQUEST_DATE,
    payload: { request, date }
})

export const changeRequestDateSuccess = () => ({
    type: CHANGE_REQUEST_DATE_SUCCESS
})

export const changeRequestDateError = (err) => ({
    type: CHANGE_REQUEST_DATE_ERROR,
    payload: err
})

export const selectRequest = (id) => ({
    type: SELECT_REQUEST,
    payload: { id }
})

export const loadCalendar = () => ({
    type: LOAD_CALENDAR
})

export const calendarLoaded = () => ({
    type: CALENDAR_LODADED
})

export const reloadRequests = () => ({
    type: RELOAD_REQUESTS
})

export const sendEmail = (data) => ({
    type: SEND_EMAIL,
    payload: data
})

export const sendEmailSuccess = (emailType) => ({
    type: SEND_EMAIL_SUCCESS,
    payload: { emailType }
})

export const sendEmailError = (error, emailType) => ({
    type: SEND_EMAIL_ERROR,
    payload: { error , emailType }
})