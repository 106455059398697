import FinanceService from './services';
import { Api } from '../../helpers/api';
import { all, call, put, takeEvery, fork } from 'redux-saga/effects';
import {
    fetchFinancesBalancesSuccess,
    fetchFinancesBalancesError,
    fetchFinancesExtractMonthSuccess,
    fetchFinancesExtractMonthError,
    fetchFinancesTransferSuccess,
    fetchFinancesTransferError
} from './actions';
import {
    FETCH_FINANCES_BALANCES,
    FETCH_FINANCES_EXTRACT_MONTH,
    FETCH_FINANCES_TRANSFER
} from './constants';

function* getFinancesBalances() {
    const res = yield call(FinanceService.getFinancesBalances);
    if(res.status === 200) {
        const { balance, transferableBalance, withheldBalance } = res.data[0];
        yield put(fetchFinancesBalancesSuccess({balance, transferableBalance, withheldBalance}));
    }
    else {
        yield put(fetchFinancesBalancesError(res));
    }
}

function* getFinancesExtractOfMonth({ payload }){
    const { startDate, endDate, download, authKey } = payload;

    const res = yield call(FinanceService.getExtractOfMonth, {
        startDate,
        endDate, 
        download,
        authKey
    });
    
    if(download === 0) {
        if(res.status === 200) {
            yield put(fetchFinancesExtractMonthSuccess(res));
        }
        else {
            yield put(fetchFinancesExtractMonthError(res));
        }
    }
    else {
        const API = new Api();
        return new Promise((resolve) => {
            const frame = document.createElement('iframe');
            frame.style.display = 'none';
            frame.setAttribute('src', `${API.getBaseURL()}/v1/payment/get-finances-extract?authKey=${authKey}&startDate=${startDate}&endDate=${endDate}`); //or any other extension
            frame.onload = () => resolve()
            document.body.appendChild(frame);
        });
    }
}

function* transferAvailableMoipBalance({ payload }){
    const { amount_to_transfer } = payload;

    // const res = yield call(FinanceService.transferAvailableMoipBalance, { 
    //     amount_to_transfer: Number(amount_to_transfer.toString().replace(/\D/g, ''))
    // });
    const res = yield call(FinanceService.transferAvailableMoipBalance, { 
        amount_to_transfer
    });

    if(res.status === 200) {
        yield put(fetchFinancesTransferSuccess(res.data));
    }
    else {
        yield put(fetchFinancesTransferError(res));
    }
}

export function* watchFetchFinancesBalances(){
    yield takeEvery(FETCH_FINANCES_BALANCES, getFinancesBalances)
}

export function* watchFetchFinancesExtractOfMonth(){
    yield takeEvery(FETCH_FINANCES_EXTRACT_MONTH, getFinancesExtractOfMonth)
}

export function* watchFetchFinancesTransfer(){
    yield takeEvery(FETCH_FINANCES_TRANSFER, transferAvailableMoipBalance)
}

function* financesSaga(){
    yield all([
        fork(watchFetchFinancesBalances),
        fork(watchFetchFinancesExtractOfMonth),
        fork(watchFetchFinancesTransfer)
    ])
}

export default financesSaga
