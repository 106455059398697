import {
    createAdventure,
    createPackage,
    createPayment,
    createVoucher,
    createUser
} from '../actions'
import { put } from 'redux-saga/effects';

/**
 * Função para normalizar um voucher disparando actions dentro da saga
 * @param {Object} voucher json com os dados do voucher não normalizados
 */
export function* normalizeVoucherOnSaga(voucher) {
    if (!!voucher.adventure) {
        const createAdventureAction = yield put(createAdventure(voucher.adventure))
        voucher.adventure_id = createAdventureAction.payload.id
    }

    if (!!voucher.payment) {
        const createPaymentAction = yield put(createPayment(voucher.payment))
        voucher.payment_id = createPaymentAction.payload.id
    }
    
    if (!!voucher.package) {
        const createPackageAction = yield put(createPackage(voucher.package))
        voucher.package_id = createPackageAction.payload.id
    }

    if (!!voucher.user) {
        const createUserAction = yield put(createUser(voucher.user))
        voucher.user_id = createUserAction.payload.id
    }
    const createVoucherAction = yield put(createVoucher(voucher))
    return { ...createVoucherAction.payload }
}