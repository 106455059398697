import { combineReducers } from "redux";
import {
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER
} from "../../constants";

export const userById = (state = {}, action) => {
    switch (action.type) {
        case CREATE_USER:
            return { ...state, [action.payload.id]: { ...action.payload } }
        case UPDATE_USER:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload }}
        case DELETE_USER:
            return { ...state, [action.payload.id]: undefined }
        default:
            return state
    }
}

export const userAllIds = (state = [], action) => {
    switch (action.type) {
        case CREATE_USER:
            return [ ...state, action.payload.id ]
        case DELETE_USER:
            return state.reduce((prevState,curId) => curId !== action.payload.id ? [ ...prevState, curId] : prevState,[])
        default:
            return state
    }
}

export default combineReducers({
    byId: userById,
    allIds: userAllIds
})