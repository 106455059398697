import {
    CHANGE_VIEW_MODE,
    CHANGE_CURRENT_DATE,
    FETCH_REQUESTS_FOR_MONTH,
    FETCH_REQUESTS_FOR_MONTH_SUCCESS,
    FETCH_REQUESTS_FOR_MONTH_ERROR,
    FETCH_REQUESTS_FOR_DAY,
    FETCH_REQUESTS_FOR_DAY_SUCCESS,
    FETCH_REQUESTS_FOR_DAY_ERROR,
    FETCH_SCHEDULE,
    FETCH_SCHEDULE_SUCCESS,
    FETCH_SCHEDULE_ERROR,
    CREATE_REQUEST,
    CREATE_REQUEST_SUCCESS,
    CREATE_REQUEST_ERROR,
    CANCEL_REQUEST,
    CANCEL_REQUEST_SUCCESS,
    CANCEL_REQUEST_ERROR,
    CANCEL_RESERVE,
    CANCEL_RESERVE_SUCCESS,
    CANCEL_RESERVE_ERROR,
    CHANGE_REQUEST_DATE,
    CHANGE_REQUEST_DATE_SUCCESS,
    CHANGE_REQUEST_DATE_ERROR,
    SELECT_REQUEST,
    VIEW_MODE_MONTH,
    CLEAR_ERROR,
    DELETE_REQUEST_ENTITY
} from '../constants'
import { LOAD_CALENDAR, CALENDAR_LODADED, RELOAD_REQUESTS, CHANGE_REQUEST_INFO, CHANGE_REQUEST_INFO_SUCCESS, CHANGE_REQUEST_INFO_ERROR, CHANGE_RESERVE_INFO, CHANGE_RESERVE_INFO_SUCCESS, CHANGE_RESERVE_INFO_ERROR, SEND_EMAIL_SUCCESS, SEND_EMAIL_ERROR } from './constants'
import { LOGOUT_USER, CLEAR_SUCCESS } from '../auth/constants'


const initialState = {
    requests: [],
    requestsPreview: [],
    fetchedDates: [],
    fetchedMonths: [],
    schedule: [],
    currentDate: new Date(),
    loadingMonth: false,
    loadingCalendar: false,
    isCalendarLoaded: false,
    loadingRequests: false,
    loadingSchedule: false,
    loadingChangeDate: false,
    loadingCancelReserve: false,
    loadingCancelRequest: false,
    loadingCreateRequest: false,
    loadingChangeRequestInfo: false,
    loadingChangeReserveInfo: false,
    currentRequest: null,
    error: null,
    success: null,
    viewMode: VIEW_MODE_MONTH,
}


const Reservations = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REQUESTS_FOR_MONTH:
            return { ...state, fetchedMonths: [ ...state.fetchedMonths, action.payload.date ], loadingMonth: true }
        case FETCH_REQUESTS_FOR_MONTH_SUCCESS:
            return { ...state, requestsPreview: [ ...state.requestsPreview, ...action.payload], loadingMonth: false }
        case FETCH_REQUESTS_FOR_MONTH_ERROR:
            return { ...state, error: action.payload, loadingMonth: false }
        case FETCH_REQUESTS_FOR_DAY:
            return { ...state, fetchedDates: [ ...state.fetchedDates, action.payload.date ], loadingRequests: true }
        case FETCH_REQUESTS_FOR_DAY_SUCCESS:
            return { ...state, requests: [ ...state.requests, ...action.payload], loadingRequests: false }
        case FETCH_REQUESTS_FOR_DAY_ERROR:
            return { ...state, error: action.payload, loadingRequests: false }
        case RELOAD_REQUESTS:
            return { ...state, requests: [], requestsPreview: [], fetchedDates: [], fetchedMonths: [], loadingRequests: true }
        case FETCH_SCHEDULE:
            return { ...state, schedule: [], loadingSchedule: true }
        case FETCH_SCHEDULE_SUCCESS:
            return { ...state, loadingSchedule: false, schedule: [ ...action.payload ] }
        case FETCH_SCHEDULE_ERROR:
            return { ...state, loadingSchedule: false, error: action.payload }
        case CHANGE_VIEW_MODE:
            return { ...state, viewMode: action.payload.mode }
        case CHANGE_CURRENT_DATE:
            return { ...state, currentDate: action.payload.date }
        case DELETE_REQUEST_ENTITY:
            return {
                ...state,
                requests: state.requests.reduce((prevState, curId) => curId === action.payload.id ? prevState : [ ...prevState, curId],[])
            }
        case CREATE_REQUEST:
            return { ...state, loadingCreateRequest: true }
        case CREATE_REQUEST_SUCCESS:
            return { ...state, requests: [ ...new Set([ ...state.requests, action.payload.id]) ], loadingCreateRequest: false }
        case CREATE_REQUEST_ERROR:
            return { ...state, error: { ...action.payload }, loadingCreateRequest: false }
        case CANCEL_RESERVE:
            return { ...state, loadingCancelReserve: true }
        case CANCEL_RESERVE_SUCCESS:
            return { ...state, loadingCancelReserve: false }
        case CANCEL_RESERVE_ERROR:
            return { ...state, loadingCancelReserve: false, error: { ...action.payload } }
        case SELECT_REQUEST:
            return { ...state, currentRequest: action.payload.id }
        case CLEAR_ERROR:
            return {...state, error: null}
        case CANCEL_REQUEST:
            return { ...state, loadingCancelRequest: true }
        case CANCEL_REQUEST_SUCCESS:
            return { ...state, loadingCancelRequest: false }
        case CANCEL_REQUEST_ERROR:
            return { ...state, loadingCancelRequest: false, error: action.payload }
        case CHANGE_REQUEST_INFO:
            return { ...state, loadingChangeRequestInfo: true }
        case CHANGE_REQUEST_INFO_SUCCESS:
            return { ...state, loadingChangeRequestInfo: false }
        case CHANGE_REQUEST_INFO_ERROR:
            return { ...state, loadingChangeRequestInfo: false, error: action.payload }
        case CHANGE_RESERVE_INFO:
            return { ...state, loadingChangeReserveInfo: true }
        case CHANGE_RESERVE_INFO_SUCCESS:
            return { ...state, loadingChangeReserveInfo: false }
        case CHANGE_RESERVE_INFO_ERROR:
            return { ...state, loadingChangeReserveInfo: false, error: action.payload }
        case CHANGE_REQUEST_DATE:
            return { ...state, loadingChangeDate: true }
        case CHANGE_REQUEST_DATE_SUCCESS:
            return { ...state, loadingChangeDate: false }
        case CHANGE_REQUEST_DATE_ERROR:
            return { ...state, loadingChangeDate: false, error: action.payload }
        case LOAD_CALENDAR:
            return { ...state, loadingCalendar: true }
        case CALENDAR_LODADED:
            return { ...state, loadingCalendar: false, isCalendarLoaded: true }
        case SEND_EMAIL_SUCCESS:
            if(["request","reserve"].includes(action.payload.emailType))
                return { ...state, success: { message: "Email enviado com sucesso!" } }
            else
                return state
        case SEND_EMAIL_ERROR:
            if(["request","reserve"].includes(action.payload.emailType))
                return { ...state, error: action.payload.error }
            else
                return state
        case LOGOUT_USER:
            return initialState;
        case CLEAR_SUCCESS:
            return { ...state, success: null }
        default:
            return state
    }
}

export default Reservations