import React from 'react'
import { Modal, ModalBody, CustomInput, ModalHeader, FormGroup, Label, FormText, Button, Input, Container, Alert } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserFields, saveBusinessAttributes, clearError } from '../../redux/actions'
import { BusinessInfo } from '../../pages/profile/BusinessInfo'
import { Check } from 'react-feather'
import { isUserAuthenticated } from '../../helpers/authUtils';
import Loader from '../Loader'
import { BankFields } from '../BankFields'
import moment from 'moment'
import { useState } from 'react'
import { Toast } from '../Toast'

const validateFieldsErrors = (user) => {
    // Valida campos para cnpj
    if (user.type === "cnpj") {
        if (!(!!user.cnpj && user.cnpj.length === 14))
            return "CNPJ"

        if (!user.social_reason) 
            return "Razão social"
    } 

    // Valida campos para cpf e cnpj
    if (!(!!user.cep && user.cep.length === 8))
        return "CEP"
    
    if (!user.address)
        return "Endereço"
    
    if (!user.number)
        return "Número"
    
    if (!user.district)
        return "Bairro"
    
    if (!user.city)
        return "Cidade"
    
    if (!(!!user.state && user.state.length === 2))
        return "Estado"
    
    if (!user.name)
        return user.type === "cpf" ? "Nome completo" : "Nome do responsável legal"
    
    if (!(!!user.cpf && user.cpf.length === 11))
        return user.type === "cpf" ? "CPF" : "CPF do responsável legal"
    
    if (!(!!user.birthday && moment(user.birthday).isValid()))
        return user.type === "cpf" ? "Data de nascimento" : "Data de nascimento do responsável legal"
    
    if (!(!!user.business_phone && user.business_phone.length >= 15))
        return "Telefone principal"

    // Valida campos bancarios
    if (!user.bank_number)
        return "Número do banco"
        
    if (!user.agency_number)
        return "Número da agência"

    if (!user.agency_check_number)
        return "Digito da agência"

    if (!user.account_number)
        return "Número da conta"

    if (!user.account_check_number)
        return "Digito da conta"

    if (!user.owner)
        return "Nome do dono da conta"

    if (!user.document)
        return "Documento do dono da conta"

    return false
}

export const ModalFirstAccess = () => {
    const { user, loadingSaveBusinessAttributes, error } = useSelector(state => state.Auth)
    const [toastMessage, setToastMessage] = useState(false)
    const dispatch = useDispatch()

    const handleUpdateFields = (field, value) => {
        dispatch(updateUserFields({ [field]: value }))
    }

    const handleConfirm = () => {
        const error = validateFieldsErrors(user)
        if (!error) {
            dispatch(saveBusinessAttributes({ ...user, first_access: true, silent: true }))
        } else {
            setToastMessage(error)
        }
    }

    if (!isUserAuthenticated())
        return ''

    return (
        <Modal size="lg" isOpen={isUserAuthenticated() && !user.juno_token && !user.admin}>
            <ModalHeader>
                Bem vindo(a) ao Offstation!
            </ModalHeader>
            <ModalBody>
                {loadingSaveBusinessAttributes && <Loader />}
                <Container>
                    <p className="lead">De hoje em diante <strong>somos parceiros!</strong></p>
                    <p>Bem vindo a essa comunidade que acredita na internet como um <strong>local para fazer
                        negócios</strong> e pode facilitar para que cada vez mais pessoas possam praticar
                        esportes, aventuras e turismo.</p>
                    <p>Tudo que desenvolvemos e fazemos é para <strong>ajudar você a cumprir seu propósito</strong>,
                    te dar as ferramentas para que tenha dinheiro e tempo para se dedicar em criar
                    experiências memoráveis para as pessoas</p>
                    <FormGroup>
                        <Label>Tipo de pessoa</Label>
                        <FormText>Iremos informar os dados fiscais necessários para formular o contrato com base na sua escolha</FormText>
                        <CustomInput onClick={() => handleUpdateFields("type", "cnpj")} checked={user.type === "cnpj"} id="type-cnpj" className="radio-card-input" type="radio" label="Pessoa Jurídica (Possui CNPJ em vigor)" />
                        <CustomInput onClick={() => handleUpdateFields("type", "cpf")} checked={user.type === "cpf"} id="type-cpf" className="radio-card-input" type="radio" label="Pessoa Física" />
                    </FormGroup>
                </Container>
                <BusinessInfo onChangeField={handleUpdateFields} {...user} noSave fullWidth />
                <Container>
                    <h4>Dados Bancários</h4>
                    <BankFields onChangeFields={handleUpdateFields} {...user} />
                </Container>
                <Container>
                    <FormGroup>
                        <Label>Código de indicação</Label>
                        <FormText>Se possuir</FormText>
                        <Input />
                    </FormGroup>
                    <CustomInput type="checkbox" id="terms-acceptance" checked={user.termsAcceptance} onChange={e => handleUpdateFields("termsAcceptance", e.target.checked)} label="Concordo com o Termo de Uso e Contrato de Prestação de Serviços" />
                    <Button onClick={() => handleConfirm()} color="primary" className="float-right" ><Check /> Salvar</Button>
                </Container>
            </ModalBody>
            <div style={{ position: 'fixed', bottom: '5px', left: '0', right: '0', zIndex: '99', margin: 'auto', maxWidth: '35vw' }}>
                {error && <Alert color="danger" isOpen={!!error} toggle={() => dispatch(clearError())}>{error.message}</Alert> }
            </div>
            {toastMessage && <Toast message={`Ainda existem campos com erro: ${toastMessage}`} clear={() => setToastMessage(undefined)} color="danger" />}
        </Modal>
    )
}
