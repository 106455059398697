import moment from 'moment'

export function getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function getRandomColorFromNumber(number) {
    const r = (number*8)%256
    const g = (number*9)%256
    const b = (number*7)%256

    return `rgb(${r},${g},${b})`
}

export const handlePhoneMask = (v) => {
    if(v.length > 15) return v.substring(0,15)
    v=v.replace(/\D/g,"");             //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v=v.replace(/(\d)(\d{4})$/,"$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
    return v
}

export const handleNumberMask = (v) => v.replace(/\D/g,"")

export const isRuleForTheDate = (date, rule) => {
    const start_date = moment(rule.start_date)
    const end_date = moment(rule.end_date)
    const isOnRangeDate = rule.end_date ? moment(date).isBetween(start_date, end_date, 'day', '[]') : moment(date).isSameOrAfter(start_date, 'day')
    const isOnWeekday = rule.weekdays.includes(moment(date).weekday());
    return isOnRangeDate && isOnWeekday
}

export function listScheduleForDate(date, schedule, requests){
    
    const rulesForDate = schedule.reduce((rulesForDay,curRule) => isRuleForTheDate(date,curRule) ? [ ...rulesForDay, curRule] : rulesForDay, [])
    const requestsForDate = requests.reduce((requestsForDay,curReq) => moment(curReq.date).isSame(moment(date), 'day') ? [ ...requestsForDay, curReq] : requestsForDay, [])
    
    let scheduleList = rulesForDate.reduce((scheduleMatrix,curRule) => ({ ...scheduleMatrix, [`${curRule.hour}:00${curRule.fk_adventure}`]: curRule }), {})

    scheduleList = requestsForDate.reduce((scheduleMatrix,curReq) => {
        let field = `${curReq.date.split(" ")[1]}${curReq.fk_adventure}`
        if (scheduleMatrix[field]) {
            if (scheduleMatrix[field].pk_request) {
                field = `${field}${curReq.pk_request}`
            }
            scheduleMatrix[field] = curReq
        } else {
            scheduleMatrix[field] = curReq
        }

        return scheduleMatrix
    }, scheduleList)

    return Object.keys(scheduleList).sort().map(key => scheduleList[key])
}

export const toggleList = (list, value, toggle) => {
    if(toggle){
        return [ ...list, value ]
    } else {
        return list.reduce((prevValue, currentValue) => currentValue !== value ? [ ...prevValue, currentValue] : prevValue, [])
    }
}

export const validateAdventureFields = (fields, adventure) => {
    let errors = []
    fields.forEach(field => {
        const value = adventure[field]
        switch (field) {
            case 'title':
                if(!value)
                    errors.push({ message: 'O campo de título é obrigatório' })
                break;
            case 'fk_category':
                if(!value)
                    errors.push({ message: 'O campo de categoria é obrigatório' })
                break;
            case 'long_description':
                if(!value)
                    errors.push({ message: 'O campo de descrição é obrigatório' })
                break;
            case 'street':
                if(!value)
                    errors.push({ message: 'O campo de endereço é obrigatório' })
                break;
            case 'termsAcceptance':
                if(!value)
                    errors.push({ message: 'É necessário aceitar os termos para continuar' })
                break;
            // case 'publishAcceptance':
            //     if(!value)
            //         errors.push({ message: 'É necessário aceitar a publicação para continuar' })
            //     break;
            case 'sell_type':
                if (!value) {
                    errors.push({ message: 'É preciso selecionar uma opção' })
                } else if (['event', 'trip', 'daily', 'schedule'].includes(adventure[field]) && !adventure.online_scheduling) {
                    errors.push({ message: 'É necessário utilizar o Offstation para selecionar esta opção' })
                }
                break;
            case 'voucher':
                errors = [ ...errors, ...validateVoucher(adventure)]
                break;
            case 'event':
                errors = [ ...errors, ...validateEvent(adventure)]
                break;
            case 'trip':
                errors = [ ...errors, ...validateTrip(adventure)]
                break;
            case 'daily':
                errors = [ ...errors, ...validateDaily(adventure)]
                break;
            case 'schedule':
                errors = [ ...errors, ...validateSchedule(adventure)]
                break;
            default:
                break;
        }
    })
    return errors
}


export const validateVoucher = (adventure) => {
    const errors = [];
    if (!adventure.packages.length) {
        errors.push({ message: "É necessário configurar pelo menos um pacote" })
    }

    if (!adventure.voucher_expiration_days) {
        errors.push({ message: "É necessário configurar a validade dos vouchers" })
    }

    if (!adventure.voucher_terms) {
        errors.push({ message: "É necessário descrever os termos de uso do voucher" })
    }
    return errors
}

export const validateEvent = (adventure) => {
    const errors = []
    if (!adventure.start_date) {
        errors.push({ message: "É necessário informar uma data de inicio" })
    }

    if (!adventure.start_hour) {
        errors.push({ message: "É necessário informar um horário de inicio" })
    }

    if (!adventure.duration) {
        errors.push({ message: "É necessário informar a duração da atividade" })
    }

    if (!adventure.min || !adventure.max || adventure.min > adventure.max) {
        errors.push({ message: "Número de participantes inválido" })
    }

    if (!adventure.packages.length) {
        errors.push({ message: "É necessário configurar pelo menos um pacote" })
    }
    return errors
}

export const validateTrip = (adventure) => {
    const errors = []
    if (!adventure.start_date) {
        errors.push({ message: "É necessário informar uma data de inicio" })
    }

    if (!adventure.start_hour) {
        errors.push({ message: "É necessário informar um horário de inicio" })
    }

    if (!adventure.duration) {
        errors.push({ message: "É necessário informar a duração da atividade" })
    }

    if (!adventure.min || !adventure.max || adventure.min > adventure.max) {
        errors.push({ message: "Número de participantes inválido" })
    }

    if (!adventure.packages.length) {
        errors.push({ message: "É necessário configurar pelo menos um pacote" })
    }

    if (!adventure.scripts.length) {
        errors.push({ message: "É necessário configurar pelo menos uma etapa do roteiro"})
    }
    return errors
}

export const validateDaily = (adventure) => {
    const errors = []
    if (!adventure.weekdays.length) {
        errors.push({ message: "É necessário estar disponível pelo menos em um dia da semana" })
    }

    if (!adventure.start_hour) {
        errors.push({ message: "É necessário informar um horário de inicio" })
    }

    if (!adventure.duration) {
        errors.push({ message: "É necessário informar a duração da atividade" })
    }

    if (!adventure.min || !adventure.max || adventure.min > adventure.max) {
        errors.push({ message: "Número de participantes inválido" })
    }

    if (!adventure.packages.length) {
        errors.push({ message: "É necessário configurar pelo menos um pacote" })
    }
    return errors
}

export const validateSchedule = (adventure) => {
    const errors = []
    if (!adventure.duration) {
        errors.push({ message: "É necessário informar a duração da atividade" })
    }

    if (!adventure.min || !adventure.max || adventure.min > adventure.max) {
        errors.push({ message: "Número de participantes inválido" })
    }

    if (!adventure.packages.length) {
        errors.push({ message: "É necessário configurar pelo menos um pacote" })
    }

    if (!adventure.scheduleRules.length) {
        errors.push({ message: "É necessário configurar pelo menos um horário" })
    }
    return errors
}

export const numberToCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);
}

export const paymentStatus = (status) => {
    switch(status) {
        default:
            return 'Não informado';
        case 'CREATED':
            return 'Criado';
        case 'WAITING':
            return 'Aguardando';
        case 'IN_ANALYSIS':
            return 'Em Análise';
        case 'PRE_AUTHORIZED':
            return 'Pré-autorizado';
        case 'AUTHORIZED':
            return 'Autorizado';
        case 'CANCELED':
            return 'Negado';
        case 'REFUNDED':
            return 'Reembolsado';
        case 'REVERSED':
            return 'Estornado';
        case 'SETTLED':
            return 'Confirmado';
    }
}

export const paymentType = (type) => {
    switch(type) {
        default:
            return 'Transferência';
        case 'CREDIT_CARD':
            return 'Cartão de Crédito';
        case 'BOLETO':
            return 'Boleto';
        case 'LOCAL':
            return 'Local';
    }
}

export const adjustMoipNumber = (number) => {
    if (!number || number === 0) return 0

    const arr = number.toString().split('')
    arr.splice(arr.length - 2, 0, '.')
    const formattedNumber = Number(arr.join(''))
    return formattedNumber
}