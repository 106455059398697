import { v4 as uuid4 } from 'uuid'
import { getRandomColor } from '../helpers/utils'

export class ScheduleRule {
    constructor(json = {}){
        this.id = json.id || uuid4()
        this.pk_schedule_rule = json.pk_schedule_rule || null
        this.adventure_id = json.adventure_id || null
        this.fk_adventure = json.fk_adventure || null
        this.hour = json.hour || ''
        this.start = json.start || '' // TODO: Trocar pra hour
        this.repeat = json.repeat === undefined || !json.end_date ? true : json.repeat
        this.start_date = json.start_date || ''
        this.end_date = json.end_date || null
        this.name = json.name || ''
        if (Array.isArray(json.weekdays)) {
            this.weekdays = json.weekdays.map((day) => day.pk_schedule_rule_weekday ? day.day : day)
        } else {
            this.weekdays = []
        }
        this.avaiablePackages = json.avaiablePackages || []
        this.tag = json.tag || getRandomColor()
    }
}

export const denormalizeScheduleRuleForCalendar = (rule, entities) => {
    rule.adventure = entities.adventure.byId[rule.adventure_id] || {}
    return { ...rule }
}