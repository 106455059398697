
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    CLEAR_ERROR,
    CLEAR_FORGET_PASSWORD
} from '../constants';

import { getLoggedInUser } from '../../helpers/authUtils';
import { UPDATE_USER_FIELDS, SAVE_BANK_ATTRIBUTES_ERROR, SAVE_BUSINESS_ATTRIBUTES_ERROR, SAVE_BUSINESS_ATTRIBUTES, SAVE_BUSINESS_ATTRIBUTES_SUCCESS, SAVE_BANK_ATTRIBUTES, SAVE_BANK_ATTRIBUTES_SUCCESS, CLEAR_SUCCESS, SEND_DOCUMENTS, SEND_DOCUMENTS_SUCCESS, SEND_DOCUMENTS_ERROR } from './constants';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
    loadingSaveBusinessAttributes: false,
    loadingSaveBankAttributes: false,
    loadingRegisterUser: false,
    loadingUpdateUser: false,
    loadingSendDocuments: false,
    passwordResetStatus: null,
    error: null,
    success: null
};


const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, user: { ...action.payload }, loading: false, error: null };
        case LOGIN_USER_ERROR:
            return { ...state, error: { ...action.payload }, loading: false };
        case REGISTER_USER:
            return { ...state, loadingRegisterUser: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, user: { ...action.payload }, loadingRegisterUser: false, error: null };
        case REGISTER_USER_ERROR:
            return { ...state, error: { ...action.payload }, loadingRegisterUser: false };
        case UPDATE_USER:
            return { ...state, loadingUpdateUser: true };
        case UPDATE_USER_SUCCESS:
            return { ...state, user: { ...action.payload }, loadingUpdateUser: false, error: null };
        case UPDATE_USER_ERROR:
            return { ...state, error: { ...action.payload }, loadingUpdateUser: false };
        case LOGOUT_USER:
            return { ...state, user: null };
        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: { ...action.payload }, loading: false, error: null };
        case FORGET_PASSWORD_ERROR:
            return { ...state, error: { ...action.payload }, loading: false };
        case CLEAR_ERROR:
            return { ...state, error: null };
        case CLEAR_FORGET_PASSWORD:
            return { ...state, passwordResetStatus: null };
        case UPDATE_USER_FIELDS:
            return { ...state, user: { ...state.user, ...action.payload }}
        case SAVE_BANK_ATTRIBUTES:
            return { ...state, loadingSaveBankAttributes: true, error: null, success: null }
        case SAVE_BANK_ATTRIBUTES_SUCCESS:
            return { ...state, loadingSaveBankAttributes: false, error: null, success: { message: "Dados salvos com sucesso!" } }
        case SAVE_BANK_ATTRIBUTES_ERROR:
            return { ...state, loadingSaveBankAttributes: false, success: null, error: { ...action.payload } }
        case SEND_DOCUMENTS:
            return { ...state, loadingSendDocuments: true, error: null, success: null }
        case SEND_DOCUMENTS_SUCCESS:
            return { ...state, loadingSendDocuments: false, error: null, success: { message: "Documentos salvos com sucesso!" } }
        case SEND_DOCUMENTS_ERROR:
            return { ...state, loadingSendDocuments: false, success: null, error: { ...action.payload } }
        case SAVE_BUSINESS_ATTRIBUTES:
            return { ...state, loadingSaveBusinessAttributes: true, error: null, success: null }
        case SAVE_BUSINESS_ATTRIBUTES_SUCCESS:
            const newState = { ...state, loadingSaveBusinessAttributes: false, error: null }

            if (action.payload.first_access)
                newState.user = { ...state.user, juno_token: action.payload.juno_token }

            if (!action.payload.silent)
                newState.success = { message: "Dados salvos com sucesso!" }
                
            return newState
        case SAVE_BUSINESS_ATTRIBUTES_ERROR:
            return { ...state, loadingSaveBusinessAttributes: false, success: null, error: { ...action.payload } }
        case CLEAR_SUCCESS:
            return { ...state, success: null }
        default:
            return { ...state };
    }
};

export default Auth;
