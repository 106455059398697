import {
    CREATE_PAYMENT,
    UPDATE_PAYMENT,
    DELETE_PAYMENT
} from '../../constants'

import { Payment } from '../../../models'

export const createPayment = (data) => ({
    type: CREATE_PAYMENT,
    payload: new Payment(data)
})

export const updatePayment = (data) => ({
    type: UPDATE_PAYMENT,
    payload: data
})

export const deletePayment = (id) => ({
    type: DELETE_PAYMENT,
    payload: { id }
})