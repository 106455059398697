import { combineReducers } from "redux";
import {
    CREATE_VOUCHER,
    UPDATE_VOUCHER,
    DELETE_VOUCHER
} from '../../constants'


export const voucherById = (state = {}, action) => {
    switch (action.type) {
        case CREATE_VOUCHER:
            return { ...state, [action.payload.id]: { ...action.payload } }
        case UPDATE_VOUCHER:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload }}
        case DELETE_VOUCHER:
            return { ...state, [action.payload.id]: undefined }
        default:
            return state
    }
}

export const voucherAllIds = (state = [], action) => {
    switch (action.type) {
        case CREATE_VOUCHER:
            return [ ...state, action.payload.id ]
        case DELETE_VOUCHER:
            return state.reduce(
                (prevState,curId) => curId !== action.payload.id ? [ ...prevState, curId] : prevState,
                [])
        default:
            return state
    }
}

export default combineReducers({
    byId: voucherById,
    allIds: voucherAllIds
})