/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    try {
        const user = getLoggedInUser();
        if (!user) {
            return false;
        }
        return true;
    } catch (error) {
        return false
    }

};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    try {
        const user = localStorage.getItem('user');
        return !!user ? JSON.parse(user) : null
    } catch (error) {
        return null
    }
};

export { isUserAuthenticated, getLoggedInUser };
